import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

const HeartIcon = (props: IIconProps) => (
  <Icon width="20" height="18" viewBox="0 0 20 18" {...props}>
    <Path d="M19.1746 3.89762C18.8754 3.20484 18.444 2.57706 17.9045 2.0494C17.3647 1.52017 16.7281 1.0996 16.0295 0.810563C15.3052 0.509654 14.5282 0.355632 13.7438 0.357438C12.6434 0.357438 11.5697 0.658777 10.6367 1.22797C10.4135 1.36413 10.2014 1.51369 10.0005 1.67663C9.79963 1.51369 9.58758 1.36413 9.36436 1.22797C8.43133 0.658777 7.35767 0.357438 6.25722 0.357438C5.46481 0.357438 4.69695 0.509223 3.97151 0.810563C3.27061 1.10074 2.63892 1.51815 2.09651 2.0494C1.55632 2.57646 1.1248 3.2044 0.826416 3.89762C0.516148 4.6186 0.357666 5.38422 0.357666 6.17217C0.357666 6.91547 0.509452 7.69003 0.810791 8.47797C1.06302 9.13646 1.42463 9.81949 1.88668 10.5092C2.61883 11.6007 3.62552 12.7391 4.87552 13.8932C6.94695 15.8061 8.99829 17.1275 9.08535 17.1811L9.61436 17.5204C9.84874 17.6699 10.1501 17.6699 10.3845 17.5204L10.9135 17.1811C11.0005 17.1253 13.0496 15.8061 15.1233 13.8932C16.3733 12.7391 17.38 11.6007 18.1121 10.5092C18.5742 9.81949 18.938 9.13646 19.188 8.47797C19.4894 7.69003 19.6411 6.91547 19.6411 6.17217C19.6434 5.38422 19.4849 4.6186 19.1746 3.89762ZM10.0005 15.7548C10.0005 15.7548 2.05409 10.6632 2.05409 6.17217C2.05409 3.89762 3.93579 2.05387 6.25722 2.05387C7.88892 2.05387 9.30409 2.96458 10.0005 4.29494C10.697 2.96458 12.1121 2.05387 13.7438 2.05387C16.0653 2.05387 17.947 3.89762 17.947 6.17217C17.947 10.6632 10.0005 15.7548 10.0005 15.7548Z" />
  </Icon>
)

export default HeartIcon
