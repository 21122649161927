import React from 'react'
import { HStack, Text, VStack } from 'native-base'

import Icon, { IIconProps } from '../../Icon'
import CMSText, { ICMSTextProps } from '../CMSText'
import { IconName } from '../../Icon/types.icon'

export interface IPlanPriceProps {
  value?: string
  frequency?: string
}

export interface IPlanDataPointProps extends ICMSTextProps {
  icon?: IIconProps
}

export const PlanPrice = ({ value, frequency }: IPlanPriceProps) => (
  <HStack justifyContent="flex-end" flex="3">
    <Text
      alignSelf="flex-start"
      fontSize="5xl"
      fontWeight="bold"
      _web={{
        fontStyle: 'italic'
      }}
      pr="1"
      color="text.50">
      $
    </Text>
    <Text
      paddingRight="10px"
      marginRight="-10px"
      fontSize="10xl"
      alignSelf="flex-end"
      fontWeight="bold"
      lineHeight="1em"
      color="text.50"
      pt="3.5"
      _web={{
        fontStyle: 'italic'
      }}
    >
      {value}
    </Text>
    {frequency && (
      <Text
        alignSelf="flex-end"
        _web={{
          fontStyle: 'italic'
        }}
        fontSize="2xl"
        fontWeight="bold"
        color="text.50">
        /{frequency}
      </Text>
    )}
  </HStack>
)

export const PlanDataPoint = ({ icon, ...textProps }: IPlanDataPointProps) => {
  const { name: iconName, ...iconProps } = icon || {}
  return (
    <VStack mt="2">
      <HStack alignItems="center" justifyContent="flex-start">
        <Icon size="3" color="secondary.500" name="check" />
        <CMSText mx="2" {...textProps} />
      </HStack>
      {icon && (
        <VStack p="0" m="0" justifyContent="center" alignItems="center">
          <Icon name={iconName as IconName} {...iconProps} />
        </VStack>
      )}
    </VStack>
  )
}

export default PlanDataPoint
