import React from 'react'
import { Icon, IIconProps, useTheme } from 'native-base'
import { Path } from 'react-native-svg'

const ManufacturerRequirementsIcon = ({ color, ...props }: IIconProps) => {
  const { colors } = useTheme() as any
  const fillColor = color || colors.secondary['500']
  return (
    <Icon {...props} width="24" height="24" viewBox="0 0 24 24">
      <Path
        d="M19.1869 15.5104H16.6285L15.8761 14.5729H20.6165L19.1743 12.763H14.4088L13.6438 11.8125H20.6165L19.1743 10.0026H12.1766L11.4868 9.15623H20.6416L19.1743 7.33331H9.05392L4 16.4219H6.0316L9.71858 9.80727L15.8134 17.3333H20.6667L19.1869 15.5104Z"
        fill={fillColor}
      />
    </Icon>
  )
}

export default ManufacturerRequirementsIcon
