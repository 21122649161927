import { extendTheme, Theme } from 'native-base'

import { IThemeColorPalette, colors } from '../colors'
import createTheme from './createTheme'

type ThemeUtil = Theme | (Record<string, any> & {})

type CreateThemeType = (palette: IThemeColorPalette) => ThemeUtil

export const createThemeBare: CreateThemeType = createTheme

export const darkTheme = extendTheme(createThemeBare(colors.darkTheme))
export const lightTheme = extendTheme(createThemeBare(colors.lightTheme))
