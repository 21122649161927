import { Box, Input } from 'native-base'
import React, { forwardRef } from 'react'
import { IInputProps } from 'native-base/lib/typescript/components/primitives/Input'
import { IFormControlProps } from 'native-base/lib/typescript/components/composites/FormControl'

import Base, { IIsolatedBaseProps, NativeBaseRef } from './Base.field'

export interface IFormInputProps extends IInputProps, IIsolatedBaseProps {
  labelProps?: IFormControlProps
  hideRightElement?: boolean
  showInputRightElementAsSuffix?: boolean
}

const FormInput = forwardRef<any, IFormInputProps>(
  (
    {
      InputLeftElement,
      InputRightElement,
      value,
      onChange,
      label,
      showAsOptional,
      showAsRequired,
      showTooltip,
      tooltipProps,
      showPartnerTooltip,
      partnerTooltipProps,
      error,
      labelProps = {},
      labelTextProps = {},
      showInputRightElementAsSuffix = false,
      hideRightElement = false,
      mr = 4,
      ...rest
    }: IFormInputProps,
    ref,
  ) => {

    const rightElementMargin = hideRightElement ? 0 : mr

    let RightElement = undefined
    if (InputRightElement && !showInputRightElementAsSuffix) {
      RightElement = <Box mr={rightElementMargin}>{hideRightElement ? undefined : InputRightElement}</Box>
    }

    const hasInternalElement = InputRightElement || InputLeftElement
    return (
      <Base
        label={label}
        showAsOptional={showAsOptional}
        error={error}
        showAsRequired={showAsRequired}
        tooltipProps={tooltipProps}
        showTooltip={showTooltip}
        partnerTooltipProps={partnerTooltipProps}
        showPartnerTooltip={showPartnerTooltip}
        InputRightElement={
          showInputRightElementAsSuffix ? InputRightElement : undefined
        }
        labelTextProps={labelTextProps}
        {...labelProps}>
        <Input
          ref={ref as NativeBaseRef}
          onChange={onChange}
          InputLeftElement={InputLeftElement ? <Box ml={4}>{InputLeftElement}</Box> : undefined}
          pl={InputLeftElement ? 2.5 : undefined}
          pr={InputRightElement ? 2.5 : undefined}
          InputRightElement={RightElement}
          value={value}
          isInvalid={!!error}
          style={hasInternalElement ? hiddenInternalBorder : undefined}
          {...rest}
        />
      </Base>
    )
  },
)

export default FormInput

const hiddenInternalBorder = { borderWidth: 0 }