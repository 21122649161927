import React from 'react'
import { HStack, ITextProps, Text, Link } from 'native-base'
import CustomTooltip, { ITooltipProps } from '../../__Helpers/Tooltip'
import TooltipPopover from '../../TooltipPopover'

export interface ISingleCMSValueText extends ITextProps {
  value: string
  pdfProps?: any
  url?: string | null | undefined
}

export interface ICMSTextProps extends ITextProps {
  values?: ISingleCMSValueText[]
  showTooltip?: boolean
  tooltipProps?: ITooltipProps
  pdfProps?: any
  mobileWeb?: boolean
  isNative?: boolean
}

const CMSText = ({
  values = [],
  showTooltip = false,
  tooltipProps = {},
  mobileWeb,
  pdfProps,
  isNative,
  ...props
}: ICMSTextProps) => {
  if (!values.length) return null
  const tooltipNativeOrWeb = isNative ? (
    <CustomTooltip placement="bottom" {...tooltipProps} />
  ) : (
    <TooltipPopover label={tooltipProps?.label} mobileWeb={mobileWeb} />
  )

  return (
    <HStack alignItems="center" justifyContent="flex-start">
      <Text>
        {values.map(({ value, pdfProps, url, ...textProps }) => {
          const text = (
            <Text key={value} {...props} {...textProps}>
              {value}
            </Text>
          )
          if (url) {
            return (
              <Link key={`link-${value}`} href={url} isExternal={url.includes('http')}>
                <Text key={value} {...props} {...textProps}> {value} </Text>
              </Link>
            )
          }
          return text
        })}
      </Text>
      {showTooltip && tooltipNativeOrWeb}
    </HStack>
  )
}

export default CMSText
