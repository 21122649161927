import React from 'react'
import { Icon, IIconProps, useTheme } from 'native-base'
import { Path } from 'react-native-svg'

const HousingDataIcon = ({ color, ...props }: IIconProps) => {
  const { colors } = useTheme() as any
  const fillColor = color || colors.primary['500']
  return (
    <Icon {...props} width="24" height="24" viewBox="0 0 24 24">
      <Path
        d="M17.188 14.8356C17.1879 14.1506 16.9723 13.4812 16.5685 12.9125C16.1648 12.3438 15.5913 11.9016 14.9209 11.6422C14.2506 11.3827 13.5138 11.3178 12.8043 11.4557C12.0948 11.5936 11.4448 11.928 10.937 12.4164C10.4291 12.9048 10.0865 13.5251 9.95261 14.1983C9.81875 14.8714 9.89975 15.5671 10.1853 16.1965C10.4708 16.826 10.948 17.3609 11.556 17.7331C12.1641 18.1053 12.8755 18.298 13.5997 18.2866C14.2558 18.2829 14.8987 18.112 15.4607 17.7919L17.0907 19.3334L18.3071 18.183L16.6771 16.6416C17.0199 16.0955 17.1967 15.4706 17.188 14.8356ZM14.9377 16.1009C14.5749 16.3962 14.1074 16.5509 13.629 16.534C13.1507 16.517 12.6967 16.3297 12.3582 16.0096C12.0197 15.6895 11.8217 15.2602 11.8038 14.8078C11.7859 14.3554 11.9494 13.9134 12.2617 13.5702C12.4377 13.4049 12.6465 13.2741 12.8761 13.1853C13.1057 13.0965 13.3516 13.0514 13.5997 13.0526C13.974 13.0507 14.3404 13.1543 14.6523 13.3501C14.9641 13.5459 15.2072 13.825 15.3508 14.152C15.4943 14.4789 15.5316 14.8389 15.458 15.1859C15.3845 15.533 15.2033 15.8515 14.9377 16.1009Z"
        fill={fillColor}
      />
      <Path
        d="M11.9941 4.66669L7.23806 8.56629V6.88682H5.53513V9.96969L3.3335 11.7757L4.4404 12.9951L5.53513 12.0978V18.1025L8.89233 18.091V16.4805H7.23806V10.7059L11.9941 6.80629L18.4409 12.0863L19.5478 12.9951L20.6668 11.7757L11.9941 4.66669Z"
        fill={fillColor}
      />
    </Icon>
  )
}

export default HousingDataIcon
