import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

const ProjectIcon = (props: IIconProps) => (
  <Icon width="24" height="14" viewBox="0 0 24 14" {...props}>
    <Path d="M21.9002 11.5505H18.3302L17.2802 10.2905H23.8952L21.8827 7.85805H15.2327L14.1652 6.58055H23.8952L21.8827 4.14805H12.1177L11.1552 3.01055H23.9302L21.8827 0.560547H7.76019L0.707687 12.7755H3.54269L8.68769 3.88555L17.1927 14.0005H23.9652L21.9002 11.5505Z" />
  </Icon>
)

export default ProjectIcon
