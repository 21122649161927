import React from 'react'
import { Icon, IIconProps, useTheme } from 'native-base'
import { Circle, Path } from 'react-native-svg'

const ActivityRings = ({ color, ...props }: IIconProps) => {
  const { colors } = useTheme() as any
  const fillColor = color || colors.secondary['500']
  const secondaryColor = colors.neutral['700']

  return (
    <Icon {...props} width="36" height="36" viewBox="0 0 36 36" >
      <Circle cx="18" cy="18" r="13.7" strokeWidth="2.7" fill="none" stroke={secondaryColor}></Circle>
      <Circle cx="18" cy="17.999" r="9.406" fill="none" stroke={secondaryColor} strokeWidth="2.7"></Circle>
      <Circle cx="18" cy="18" r="5.106" fill="none" stroke={secondaryColor} strokeWidth="2.7"></Circle>
      <Path d="M 16.365 27.275 C 16.237 27.999 16.721 28.698 17.455 28.735 C 18.552 28.791 19.654 28.678 20.721 28.398 C 22.22 28.007 23.615 27.296 24.813 26.314 C 26.011 25.333 26.982 24.105 27.662 22.713 C 28.341 21.321 28.711 19.8 28.747 18.252 C 28.784 16.704 28.485 15.166 27.873 13.744 C 27.26 12.322 26.347 11.049 25.197 10.013 C 24.046 8.976 22.686 8.2 21.208 7.738 C 20.154 7.409 19.059 7.245 17.961 7.249 C 17.226 7.251 16.709 7.927 16.803 8.656 C 16.896 9.385 17.566 9.889 18.3 9.916 C 19.016 9.943 19.727 10.064 20.414 10.279 C 21.525 10.627 22.549 11.21 23.415 11.99 C 24.28 12.77 24.967 13.728 25.428 14.798 C 25.889 15.867 26.114 17.024 26.086 18.189 C 26.059 19.354 25.78 20.499 25.269 21.546 C 24.758 22.593 24.027 23.517 23.126 24.255 C 22.225 24.993 21.175 25.528 20.048 25.823 C 19.351 26.006 18.636 26.093 17.92 26.086 C 17.185 26.079 16.492 26.551 16.365 27.275 Z" stroke-width="4.28571" fill={fillColor} fill-rule="evenodd" />

      <Path fill-rule="evenodd" clip-rule="evenodd" d="M 14.154 13.316 C 13.826 13.274 13.49 13.374 13.241 13.646 C 13.027 13.88 12.831 14.129 12.654 14.391 C 12.641 14.41 12.629 14.429 12.616 14.448 C 12.179 15.111 11.864 15.854 11.694 16.643 C 11.357 18.209 11.615 19.844 12.417 21.23 C 13.219 22.616 14.508 23.655 16.033 24.143 C 17.559 24.631 19.211 24.535 20.669 23.872 C 22.127 23.209 23.286 22.028 23.921 20.557 C 24.556 19.087 24.622 17.433 24.104 15.917 C 23.587 14.402 22.525 13.133 21.124 12.357 C 20.417 11.966 19.65 11.714 18.864 11.608 C 18.841 11.605 18.818 11.602 18.796 11.599 C 18.482 11.56 18.166 11.544 17.849 11.552 C 17.48 11.56 17.184 11.748 17 12.023 C 16.894 12.181 16.826 12.368 16.803 12.567 C 16.786 12.715 16.794 12.87 16.83 13.024 C 16.956 13.558 17.392 13.928 17.909 14.12 C 17.918 14.123 17.926 14.126 17.935 14.129 C 18.02 14.16 18.108 14.186 18.196 14.207 C 18.285 14.228 18.375 14.244 18.466 14.255 C 18.94 14.314 19.402 14.463 19.827 14.698 C 20.647 15.152 21.269 15.895 21.571 16.782 C 21.874 17.668 21.836 18.636 21.464 19.496 C 21.093 20.356 20.415 21.048 19.561 21.435 C 18.709 21.823 17.742 21.88 16.849 21.594 C 15.957 21.308 15.203 20.701 14.734 19.89 C 14.264 19.079 14.114 18.122 14.311 17.206 C 14.413 16.731 14.605 16.285 14.872 15.889 C 14.923 15.813 14.97 15.735 15.013 15.654 C 15.055 15.573 15.093 15.491 15.126 15.406 C 15.13 15.398 15.133 15.389 15.136 15.38 C 15.331 14.865 15.34 14.293 15.02 13.847 C 14.928 13.718 14.817 13.611 14.694 13.526 C 14.528 13.413 14.342 13.341 14.154 13.316 Z" fill={fillColor} />

      <Path fill-rule="evenodd" clip-rule="evenodd" d="M 18.142 5.632 C 18.179 5.636 18.216 5.638 18.253 5.639 C 20.325 5.681 22.361 6.244 24.169 7.285 C 26.367 8.55 28.122 10.461 29.197 12.758 C 30.273 15.054 30.616 17.626 30.18 20.124 C 29.744 22.622 28.551 24.926 26.761 26.723 C 24.972 28.52 22.673 29.724 20.177 30.17 C 17.681 30.617 15.108 30.285 12.806 29.219 C 10.505 28.154 8.587 26.407 7.312 24.215 C 6.263 22.411 5.692 20.378 5.64 18.306 C 5.639 18.269 5.637 18.232 5.633 18.195 C 5.629 18.152 5.623 18.109 5.616 18.067 C 5.501 17.429 5 16.907 4.341 16.852 C 3.602 16.79 2.946 17.339 2.95 18.081 C 2.951 18.179 2.952 18.276 2.955 18.373 C 2.955 18.388 2.955 18.404 2.956 18.419 C 3.026 20.925 3.721 23.383 4.99 25.565 C 6.542 28.234 8.876 30.361 11.678 31.657 C 14.479 32.954 17.611 33.358 20.65 32.815 C 23.689 32.271 26.487 30.806 28.665 28.619 C 30.843 26.431 32.296 23.627 32.826 20.586 C 33.357 17.545 32.939 14.414 31.63 11.618 C 30.321 8.823 28.185 6.497 25.509 4.957 C 23.322 3.697 20.86 3.013 18.354 2.954 C 18.339 2.953 18.324 2.953 18.308 2.953 C 18.211 2.951 18.114 2.95 18.017 2.95 C 17.275 2.949 16.704 3.342 16.793 4.346 C 16.851 5.004 17.376 5.503 18.014 5.615 C 18.056 5.623 18.099 5.628 18.142 5.632 Z" fill={fillColor} />
    </Icon>
  )
}

export default ActivityRings
