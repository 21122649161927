import React from 'react'
import { Icon, IIconProps, useTheme } from 'native-base'
import { Path } from 'react-native-svg'

const CodeLockIcon = ({ color, ...props }: IIconProps) => {
  const { colors } = useTheme() as any
  const fillColor = color || colors.text['50']
  return (
    <Icon width="20" height="20" viewBox="0 0 20 20" {...props}>
      <Path
        d="M10 1.875C7.59766 1.875 5.625 3.84766 5.625 6.25V8.125H4.75C4.19772 8.125 3.75 8.57272 3.75 9.125V17.125C3.75 17.6773 4.19772 18.125 4.75 18.125H15.25C15.8023 18.125 16.25 17.6773 16.25 17.125V9.125C16.25 8.57272 15.8023 8.125 15.25 8.125H14.375V6.25C14.375 3.84766 12.4023 1.875 10 1.875ZM10 3.125C11.7212 3.125 13.125 4.52881 13.125 6.25V8.125H6.875V6.25C6.875 4.52881 8.27881 3.125 10 3.125ZM5 9.375H15V16.875H5V9.375Z"
        fill={fillColor}
      />
      <Path
        d="M10.597 11.216C10.3677 11.216 10.1623 11.2613 9.98099 11.352C9.80499 11.4427 9.65566 11.5733 9.53299 11.744C9.41033 11.9093 9.31699 12.112 9.25299 12.352C9.19433 12.592 9.16499 12.8613 9.16499 13.16C9.16499 13.5653 9.21299 13.912 9.30899 14.2C9.41033 14.4827 9.56766 14.6987 9.78099 14.848C9.99433 14.9973 10.2663 15.072 10.597 15.072C10.8317 15.072 11.0637 15.0453 11.293 14.992C11.5277 14.9387 11.781 14.864 12.053 14.768V15.784C11.8023 15.8853 11.5543 15.96 11.309 16.008C11.0637 16.056 10.789 16.08 10.485 16.08C9.89833 16.08 9.41299 15.96 9.02899 15.72C8.65033 15.4747 8.37033 15.1333 8.18899 14.696C8.00766 14.2533 7.91699 13.7387 7.91699 13.152C7.91699 12.72 7.97566 12.3253 8.09299 11.968C8.21033 11.6053 8.38099 11.2933 8.60499 11.032C8.83433 10.7707 9.11433 10.568 9.44499 10.424C9.78099 10.28 10.165 10.208 10.597 10.208C10.8797 10.208 11.1623 10.2453 11.445 10.32C11.733 10.3893 12.0077 10.4853 12.269 10.608L11.877 11.592C11.6637 11.4907 11.4477 11.4027 11.229 11.328C11.0157 11.2533 10.805 11.216 10.597 11.216Z"
        fill={fillColor}
      />
    </Icon>
  )
}

export default CodeLockIcon
