import React from 'react'
import { View } from 'native-base'
import { Svg, Defs, LinearGradient, Path, Stop } from 'react-native-svg'
import { isWeb, isAndroid } from '../../../utils'

interface IBaseDiagramImageProps {
  MeassureComponent: React.FC<any>
}

const originalHeight = 650
const originalWidth = 610
const aspectRatio = originalWidth / originalHeight

const nativeStyle = isAndroid
  ? { aspectRatio }
  : { height: 'auto', aspectRatio }

const BaseDiagramImage = ({ MeassureComponent }: IBaseDiagramImageProps) => (
  <View style={{ aspectRatio }} w="100%" h="auto">
    <Svg
      viewBox={`0 0 ${originalWidth} ${originalHeight}`}
      {...(isWeb ? { height: 'auto' } : { style: nativeStyle })}>
      <Defs>
        <LinearGradient id="a" x1="1.5" y1="650.69" x2="1.46" y2="649.71">
          <Stop offset="0" stopColor="#3c679a" />
          <Stop offset="1" />
        </LinearGradient>
        <LinearGradient id="b" x1="1.58" y1="650.67" x2="1.48" y2="650.11" />
      </Defs>
      <Path
        d="M533.31,42.08v67.44L287.47,328.59l26.53,11-4.82,251-4.83.94L296,586.18l-25.44,7.1V602l-5.54,1.2,1.1-192.46-78.53,11.68v14.47h-14.9L.22,335.46l.52-58,172,84.69Z"
        fill="#08101b"
      />
      <Path
        d="M272,367.73l27.63-2.49L296,586.8l-25.14,6.3Z"
        opacity="0.5"
        fill="#2f3e4f"
      />
      <Path
        d="M73.45,378.71v84.93l191.2,139.58,1.2-192.57L187.33,422.4v14.28H172.77Z"
        opacity="0.5"
        fill="url(#b)"
      />
      <Path
        d="M260.77,52.89,0,277H0l-.28.24-.13,58.87,72.81,42.85v85.28L264.48,604l.32.23,6.25-1.67.18-8.52L296,587.42l8.1,5,5.62-1.2,4.83-251.8v-.57l-25.86-10.25,245.4-218.51.28-.24V40.51Zm12.57,289.63,34.79-2-.44,23.12-40.61,3.29.1-18.8Zm30.09,247.53-6.49-4,3.21-220.19,7.51-.61ZM298.5,366,295.3,585.9l-24.05,6.4,1.86-224.22Zm-33.06,1-12.43,1-5.21-2.28,17.73-16.14Zm-.24,43L188.42,421.4v-1.22l76.78-11.43Zm0-2.95-11.52,1.72V369.65l11.73-.95ZM246.47,367l5.58,2.45v38.65L222,389.3ZM220.7,390.45l30.08,18.79-60.63,9ZM173.61,362.9l13.17-.49v73.48L173.6,436Zm13.66-2.16-12.22.45,357.66-318V52ZM261.41,54.5,531.25,42.3,172.65,361.12,2,277.4ZM1.21,335.14l.13-56.23L172,363v72.31L73.62,377.75h0ZM74,379.89l98.83,57.73,15.57-.1V423.07l76.77-11.37-1.05,190L74,463.37ZM265.79,602.23l1.28-233.66,4.39-.36-1.87,225.16h0l-.17,7.89Zm42.29-12.39-3,.65,4.7-250.07,3.09-.18Zm1.86-251.08-34.67,2,12-11Zm-24.35-9.65h0l-97.19,88.49V361.92L532.71,54.25v54.82Z"
        fill="#16375b"
      />
      <Path
        d="M234.57,335.62c30.66,0,54,25.36,54,56.66"
        fill="none"
        stroke="#9d9d9d"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <MeassureComponent />
    </Svg>
  </View>
)

export default BaseDiagramImage
