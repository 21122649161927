import React from 'react'
import { Icon } from 'native-base'
import { Path } from 'react-native-svg'
import { ICustomIconProps } from '../interfaces'

const JurisdictionAssignment = ({
  color = "#FFF" as any,
  ...props }: ICustomIconProps) => {
  const fillColor = color || "#fff" as any

  return (
    <Icon width="30" height="30" viewBox="0 0 30 30" {...props}>
      <Path
        d="M19.2687 14.6625L17.3937 12.7875C16.9301 12.3272 16.3033 12.0689 15.65 12.0689C14.9967 12.0689 14.3699 12.3272 13.9062 12.7875L8.76872 18.0375C8.30959 18.4979 8.05176 19.1216 8.05176 19.7719C8.05176 20.4221 8.30959 21.0458 8.76872 21.5063L10.6437 23.3813C11.1074 23.8415 11.7342 24.0998 12.3875 24.0998C13.0408 24.0998 13.6676 23.8415 14.1312 23.3813L15.8187 21.6938L20.7312 26.6063L22.6062 24.7313L17.6187 19.8L19.2687 18.15C19.729 17.6864 19.9873 17.0596 19.9873 16.4063C19.9873 15.7529 19.729 15.1261 19.2687 14.6625ZM12.3312 21.3563L10.7375 19.7813L15.7062 14.8125L17.3 16.4063L12.3312 21.3563Z"
        fill={fillColor}
      />
      <Path
        d="M18.95 3.07499L11.45 5.02499L2.63745 2.73749V22.3125L5.26245 23.0062V6.13124L10.1375 7.38749V13.5187L12.7625 10.9312V7.38749L17.6562 6.11249V9.84374L20.2812 12.3562V6.13124L25.1375 7.38749V19.6125L21.575 18.6937V21.3937L27.7625 23.0062V5.36249L18.95 3.07499Z"
        fill={fillColor}
      />
    </Icon>
  )

}
export default JurisdictionAssignment
