import React from 'react'
import { Icon, IIconProps, useTheme } from 'native-base'
import { Path } from 'react-native-svg'

const BldgCodeEnforcementIcon = ({ color, ...props }: IIconProps) => {
  const { colors } = useTheme() as any
  const fillColor = color || colors.secondary['500']
  return (
    <Icon width="36" height="36" viewBox="0 0 36 36"  {...props} >
      <Path d="M22.5898 14.355H13.1173V17.505H22.5898V14.355Z" fill={fillColor} />
      <Path d="M17.8423 20.3175H13.1173V23.4675H17.8423V20.3175Z" fill={fillColor} />
      <Path d="M20.6773 6.4575H19.0348C19.3949 6.27101 19.698 5.99064 19.9119 5.64606C20.1257 5.30148 20.2425 4.9055 20.2498 4.5C20.2498 3.90326 20.0128 3.33097 19.5908 2.90901C19.1688 2.48705 18.5965 2.25 17.9998 2.25C17.4031 2.25 16.8308 2.48705 16.4088 2.90901C15.9869 3.33097 15.7498 3.90326 15.7498 4.5C15.7571 4.9055 15.8739 5.30148 16.0878 5.64606C16.3016 5.99064 16.6047 6.27101 16.9648 6.4575H15.3673C14.456 6.45796 13.5724 6.77094 12.8641 7.34421C12.1557 7.91747 11.6653 8.71634 11.4748 9.6075H24.5698C24.3793 8.71634 23.8889 7.91747 23.1806 7.34421C22.4722 6.77094 21.5886 6.45796 20.6773 6.4575Z" fill={fillColor} />
      <Path d="M27.0448 22.5675L22.1623 27.4275L21.9373 27.8325H21.8698L21.5998 27.4275L20.1373 25.965L17.9098 28.1925L21.8923 32.1525L29.2723 24.795L27.0448 22.5675Z" fill={fillColor} />
      <Path d="M29.2498 9.6075C29.2498 8.77207 28.9179 7.97085 28.3272 7.38011C27.7365 6.78937 26.9352 6.4575 26.0998 6.4575V20.25H29.2498V9.6075Z" fill={fillColor} />
      <Path d="M10.0123 28.1475V6.4575C9.17687 6.4575 8.37566 6.78937 7.78492 7.38011C7.19418 7.97085 6.8623 8.77207 6.8623 9.6075V28.1475C6.8623 28.6675 6.96473 29.1825 7.16374 29.6629C7.36275 30.1434 7.65444 30.5799 8.02216 30.9476C8.38988 31.3154 8.82643 31.6071 9.30688 31.8061C9.78733 32.0051 10.3023 32.1075 10.8223 32.1075H15.7498V28.9575H10.8223C10.6075 28.9575 10.4015 28.8722 10.2495 28.7203C10.0976 28.5684 10.0123 28.3623 10.0123 28.1475Z" fill={fillColor} />
    </Icon>
  )
}

export default BldgCodeEnforcementIcon
