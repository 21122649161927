import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

const MenuIcon = (props: IIconProps) => (
  <Icon width="29" height="28" viewBox="0 0 29 28" {...props}>
    <Path d="M2.83325 21.7778V19.1852H26.1666V21.7778H2.83325ZM2.83325 15.2963V12.7037H26.1666V15.2963H2.83325ZM2.83325 8.81482V6.22223H26.1666V8.81482H2.83325Z" />
  </Icon>
)

export default MenuIcon
