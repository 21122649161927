import React from 'react'
import { Icon } from 'native-base'
import { Path } from 'react-native-svg'
import { ICustomIconProps } from '../interfaces'

const MapIcon = ({
  fillColor = "#1AA7FF",
  ...props }: ICustomIconProps) => (
  <Icon {...props} viewBox="0 0 28 29">
    <Path
      d="M9.62843 3.73918C9.91066 3.5779 10.2548 3.56835 10.5456 3.71372L17.858 7.36992L24.2118 3.73918C24.5127 3.56724 24.8824 3.56847 25.1821 3.74242C25.4819 3.91637 25.6663 4.23674 25.6663 4.58331V20.1389C25.6663 20.4878 25.4794 20.8099 25.1765 20.983L18.3709 24.8719C18.0887 25.0332 17.7445 25.0427 17.4538 24.8973L10.1414 21.2411L3.78759 24.8719C3.48668 25.0438 3.117 25.0426 2.81725 24.8686C2.5175 24.6947 2.33301 24.3743 2.33301 24.0278V8.4722C2.33301 8.12331 2.51995 7.80117 2.82287 7.62807L9.62843 3.73918ZM10.1414 5.68558L4.27745 9.0364V22.3524L9.62843 19.2947C9.91066 19.1335 10.2548 19.1239 10.5456 19.2693L17.858 22.9255L23.7219 19.5747V6.25862L18.3709 9.31632C18.0887 9.4776 17.7445 9.48715 17.4538 9.34178L10.1414 5.68558Z"
      fill={fillColor}
    />
    <Path
      d="M10.1109 3.61108C10.6478 3.61108 11.0831 4.04636 11.0831 4.58331V20.1389C11.0831 20.6758 10.6478 21.1111 10.1109 21.1111C9.57395 21.1111 9.13867 20.6758 9.13867 20.1389V4.58331C9.13867 4.04636 9.57395 3.61108 10.1109 3.61108Z"
      fill={fillColor}
    />
    <Path
      d="M17.8892 7.5C18.4262 7.5 18.8614 7.93528 18.8614 8.47222V24.0278C18.8614 24.5647 18.4262 25 17.8892 25C17.3523 25 16.917 24.5647 16.917 24.0278V8.47222C16.917 7.93528 17.3523 7.5 17.8892 7.5Z"
      fill={fillColor}
    />
  </Icon>
)

export default MapIcon