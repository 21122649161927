import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

const BulbIcon = (props: IIconProps) => (
  <Icon width="16" height="20" viewBox="0 0 16 20" {...props}>
    <Path d="M10.6782 18.3929H5.32108C5.22287 18.3929 5.14251 18.4732 5.14251 18.5714V19.2857C5.14251 19.6808 5.46171 20 5.85679 20H10.1425C10.5376 20 10.8568 19.6808 10.8568 19.2857V18.5714C10.8568 18.4732 10.7764 18.3929 10.6782 18.3929ZM7.99965 0C3.95724 0 0.678223 3.27902 0.678223 7.32143C0.678223 10.0312 2.15144 12.3973 4.33894 13.6629V16.25C4.33894 16.6451 4.65813 16.9643 5.05322 16.9643H10.9461C11.3412 16.9643 11.6604 16.6451 11.6604 16.25V13.6629C13.8479 12.3973 15.3211 10.0312 15.3211 7.32143C15.3211 3.27902 12.0421 0 7.99965 0ZM10.8546 12.2723L10.0532 12.7366V15.3571H5.94608V12.7366L5.14474 12.2723C3.38804 11.2567 2.28537 9.38616 2.28537 7.32143C2.28537 4.16518 4.8434 1.60714 7.99965 1.60714C11.1559 1.60714 13.7139 4.16518 13.7139 7.32143C13.7139 9.38616 12.6113 11.2567 10.8546 12.2723Z" />
  </Icon>
)

export default BulbIcon
