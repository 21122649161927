import React from 'react'
import { Icon, IIconProps, useTheme } from 'native-base'
import { Path } from 'react-native-svg'

const PermitCalculatorIcon = ({ color, ...props }: IIconProps) => {
  const { colors } = useTheme() as any
  const fillColor = color || colors.secondary['500']
  return (
    <Icon width="24" height="24" viewBox="0 0 24 24">
      <Path
        d="M11.3537 12.0588L11.2101 12.3233H11.167L10.9947 12.0588L9.7024 10.7359L8.28086 12.191L11.1814 15.1601L15.8911 10.3391L14.4696 8.88395L11.3537 12.0588Z"
        fill={fillColor}
      />
      <Path
        d="M21.3332 11.3091L19.6245 9.48657L19.9691 6.97319L19.1793 5.82673L16.7383 5.38579L15.5465 3.10757L14.2255 2.66663L11.9998 3.81308L9.7742 2.71072L8.45317 3.10757L7.29009 5.38579L4.82035 5.82673L4.00189 6.97319L4.3465 9.48657L2.6665 11.3091V12.779L4.37522 14.5574L4.03061 17.0561L4.84907 18.2173L7.29009 18.6582L8.45317 20.8923L9.7742 21.3333L11.9998 20.2162L14.2255 21.3333L15.5465 20.8923L16.7096 18.6582L19.1506 18.2173L19.9691 17.0561L19.6245 14.5574L21.3332 12.7349V11.3091ZM17.8296 13.4845L17.5568 14.337L17.8439 16.3653L15.8768 16.7181L15.1588 17.2472L14.2255 19.0404L12.445 18.1438H11.5547L9.7742 19.0404L8.84086 17.2472L8.12291 16.7181L6.15574 16.3359L6.44291 14.3076L6.17009 13.4551L4.7342 11.9853L6.17009 10.5154L6.44291 9.64825L6.15574 7.63461L8.12291 7.32594L8.84086 6.79681L9.7742 4.98894L11.5547 5.88552H12.445L14.2255 4.98894L15.1588 6.79681L15.8768 7.32594L17.8439 7.6787L17.5568 9.69235L17.8296 10.5595L19.2655 12.0294L17.8296 13.4845Z"
        fill={fillColor}
      />
    </Icon>
  )
}

export default PermitCalculatorIcon
