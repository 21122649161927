import  { forwardRef } from 'react'
import { IFormControlProps } from 'native-base/lib/typescript/components/composites/FormControl'

import Base, { IIsolatedBaseProps, NativeBaseRef } from './Base.field'
import Select, { SelectProps } from '../../Select'

export interface ISelectOptionsTypes {
  value: string
  label: string
}

export interface IFormSelectProps extends SelectProps, IIsolatedBaseProps {
  options: ISelectOptionsTypes[]
  labelProps?: IFormControlProps
}

const FormSelect = forwardRef<any, IFormSelectProps>(
  (
    {
      onChange,
      label,
      options = [],
      showAsOptional,
      showAsRequired,
      showTooltip,
      tooltipProps,
      labelProps = {},
      error,
      ...props
    }: IFormSelectProps,
    ref,
  ) => (
    <Base
      label={label}
      showAsOptional={showAsOptional}
      showAsRequired={showAsRequired}
      showTooltip={showTooltip}
      error={error}
      tooltipProps={tooltipProps}
      {...labelProps}>
      <Select
        ref={ref as NativeBaseRef}
        onChange={onChange}
        options={options}
        {...props} />
    </Base>
  )
)

export default FormSelect
