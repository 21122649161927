import React from 'react'
import { Icon, IIconProps, useTheme } from 'native-base'
import { Path } from 'react-native-svg'

const VentIcon = ({ color, ...props }: IIconProps) => {
  const { colors } = useTheme() as any
  const fillColor = color || colors.text['500']
  return (
    <Icon width="31" height="30" viewBox="0 0 31 30" {...props}>
      <Path
        d="M23 8.02502H7.9812V13.0125H23V8.02502Z"
        fill={fillColor}
      />
      <Path
        d="M23.0937 2.83124H7.8687C6.55912 2.83123 5.3029 3.35017 4.37513 4.27442C3.44737 5.19868 2.92366 6.45292 2.9187 7.76249V22.3125C2.9187 23.6253 3.44022 24.8844 4.36852 25.8127C5.29683 26.741 6.55588 27.2625 7.8687 27.2625H23.0937C24.4065 27.2625 25.6656 26.741 26.5939 25.8127C27.5222 24.8844 28.0437 23.6253 28.0437 22.3125V7.76249C28.0387 6.45292 27.515 5.19868 26.5873 4.27442C25.6595 3.35017 24.4033 2.83123 23.0937 2.83124ZM25.4187 22.3125C25.4138 22.9276 25.1673 23.5161 24.7323 23.9511C24.2973 24.3861 23.7088 24.6326 23.0937 24.6375H7.8687C7.25359 24.6326 6.66506 24.3861 6.2301 23.9511C5.79513 23.5161 5.5486 22.9276 5.5437 22.3125V7.76249C5.54865 7.14911 5.79578 6.56255 6.23127 6.13057C6.66676 5.6986 7.25531 5.45622 7.8687 5.45624H23.0937C23.7071 5.45622 24.2956 5.6986 24.7311 6.13057C25.1666 6.56255 25.4138 7.14911 25.4187 7.76249V22.3125Z"
        fill={fillColor}
      />
      <Path
        d="M8.50623 21.2625L7.19373 16.875C7.19373 16.875 7.19373 16.7438 7.30623 16.7438H8.39373C8.42854 16.7438 8.46192 16.7576 8.48653 16.7822C8.51115 16.8068 8.52498 16.8402 8.52498 16.875L9.18123 19.6125L9.87498 16.875C9.87952 16.8436 9.89532 16.8148 9.91946 16.7941C9.9436 16.7734 9.97443 16.7622 10.0062 16.7625H11.075C11.075 16.7625 11.2062 16.7625 11.1875 16.8938L9.87498 21.3188C9.86647 21.3518 9.84698 21.3809 9.81972 21.4013C9.79245 21.4218 9.75904 21.4324 9.72498 21.4313H8.65623C8.63485 21.4314 8.61368 21.427 8.59414 21.4184C8.5746 21.4097 8.55714 21.3969 8.54294 21.3809C8.52873 21.365 8.51811 21.3461 8.51178 21.3257C8.50544 21.3053 8.50355 21.2837 8.50623 21.2625Z"
        fill={fillColor}
      />
      <Path
        d="M14.8626 17.775H12.9876V18.3562H14.0938C14.111 18.3562 14.1281 18.3596 14.144 18.3662C14.16 18.3728 14.1744 18.3825 14.1866 18.3947C14.1988 18.4069 14.2085 18.4213 14.2151 18.4373C14.2217 18.4532 14.2251 18.4702 14.2251 18.4875V19.2937C14.2284 19.3117 14.2273 19.3302 14.2219 19.3477C14.2165 19.3651 14.2069 19.381 14.194 19.3939C14.1811 19.4069 14.1652 19.4165 14.1478 19.4219C14.1303 19.4273 14.1118 19.4283 14.0938 19.425H12.9126V20.0812H14.7876C14.8031 20.0776 14.8192 20.0781 14.8345 20.0824C14.8498 20.0868 14.8638 20.095 14.875 20.1063C14.8863 20.1175 14.8945 20.1315 14.8989 20.1468C14.9032 20.1621 14.9037 20.1782 14.9001 20.1937V21.0187C14.9001 21.0187 14.9001 21.15 14.7876 21.15H11.7313C11.7133 21.1533 11.6948 21.1523 11.6774 21.1469C11.6599 21.1415 11.644 21.1319 11.6311 21.1189C11.6182 21.106 11.6086 21.0901 11.6032 21.0727C11.5978 21.0552 11.5967 21.0367 11.6001 21.0187V16.875C11.5967 16.857 11.5978 16.8385 11.6032 16.821C11.6086 16.8036 11.6182 16.7877 11.6311 16.7748C11.644 16.7618 11.6599 16.7523 11.6774 16.7469C11.6948 16.7414 11.7133 16.7404 11.7313 16.7437H14.8626C14.8626 16.7437 14.9751 16.7437 14.9751 16.875V17.7C14.9728 17.7124 14.9681 17.7243 14.9612 17.7348C14.9543 17.7454 14.9453 17.7544 14.9348 17.7614C14.9243 17.7685 14.9125 17.7733 14.9001 17.7756C14.8876 17.7779 14.8749 17.7777 14.8626 17.775Z"
        fill={fillColor}
      />
      <Path
        d="M15.5375 21.2437V16.875C15.5375 16.875 15.5375 16.7437 15.65 16.7437H16.625C16.6617 16.7434 16.6973 16.7567 16.7249 16.7808C16.7526 16.805 16.7704 16.8385 16.775 16.875L18.0875 19.0687V16.875C18.0841 16.857 18.0852 16.8385 18.0906 16.821C18.096 16.8036 18.1056 16.7877 18.1185 16.7748C18.1314 16.7618 18.1473 16.7523 18.1648 16.7469C18.1822 16.7414 18.2008 16.7404 18.2187 16.7437H19.2125C19.2473 16.7437 19.2807 16.7576 19.3053 16.7822C19.3299 16.8068 19.3437 16.8402 19.3437 16.875V21.2812C19.3437 21.316 19.3299 21.3494 19.3053 21.374C19.2807 21.3987 19.2473 21.4125 19.2125 21.4125H18.3125C18.2812 21.4123 18.2507 21.4034 18.2242 21.3868C18.1977 21.3703 18.1764 21.3467 18.1625 21.3187L16.8312 19.0687V21.2625C16.8312 21.3562 16.8312 21.3937 16.7187 21.3937H15.65C15.65 21.3937 15.5375 21.3187 15.5375 21.2437Z"
        fill={fillColor}
      />
      <Path
        d="M23.7687 16.875V17.7C23.7721 17.718 23.771 17.7365 23.7656 17.754C23.7602 17.7714 23.7506 17.7873 23.7377 17.8002C23.7248 17.8132 23.7089 17.8227 23.6914 17.8282C23.674 17.8336 23.6554 17.8346 23.6375 17.8313H22.5687V21.2438C22.5687 21.2438 22.5687 21.375 22.4562 21.375H21.4437C21.4089 21.375 21.3755 21.3612 21.3509 21.3366C21.3263 21.312 21.3125 21.2786 21.3125 21.2438V17.8313H20.2625C20.2277 17.8313 20.1943 17.8174 20.1697 17.7928C20.1451 17.7682 20.1312 17.7348 20.1312 17.7V16.875C20.1312 16.8402 20.1451 16.8068 20.1697 16.7822C20.1943 16.7576 20.2277 16.7438 20.2625 16.7438H23.7312C23.751 16.7585 23.7654 16.7793 23.7722 16.8031C23.7789 16.8268 23.7777 16.8521 23.7687 16.875Z"
        fill={fillColor}
      />
    </Icon>
  )
}

export default VentIcon
