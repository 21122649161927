import React from 'react'
import { Icon, IIconProps, useTheme } from 'native-base'
import { Path } from 'react-native-svg'

const ContactPhoneOutlinedIcon = ({ color, ...props }: IIconProps) => {
  const { colors } = useTheme() as any
  const fillColor = color || colors.text['50']
  return (
    <Icon viewBox="0 0 16 17" color={fillColor} {...props}>
        <Path
          fill="currentColor"
          d="m14.52 3.622-1.902-1.9a1.228 1.228 0 0 0-1.743 0L8.827 3.767a1.232 1.232 0 0 0 0 1.746l1.6 1.602a7.24 7.24 0 0 1-1.543 2.273 7.254 7.254 0 0 1-2.271 1.546l-1.6-1.601a1.228 1.228 0 0 0-1.743 0l-2.05 2.044a1.233 1.233 0 0 0 0 1.747l1.9 1.9a2.138 2.138 0 0 0 1.846.594c2.365-.389 4.711-1.648 6.606-3.54 1.893-1.897 3.15-4.244 3.543-6.61a2.128 2.128 0 0 0-.595-1.846Zm-.671 1.634c-.349 2.105-1.48 4.205-3.186 5.91-1.706 1.706-3.804 2.838-5.91 3.186a.84.84 0 0 1-.728-.236L2.16 12.25l1.979-1.98 2.14 2.143.015.016.386-.143a8.595 8.595 0 0 0 5.102-5.103l.143-.386-2.158-2.155 1.979-1.98 1.866 1.865a.836.836 0 0 1 .237.729Z"
        />
    </Icon>
  )
}

export default ContactPhoneOutlinedIcon
