import React from 'react'
import { Icon, IIconProps } from 'native-base'
import { G, Rect, Defs, Path, ClipPath } from 'react-native-svg'

import { PlanType } from '../types'

const PlanNationalIcon = (props: PlanType) => (
  <Icon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <Defs>
      <ClipPath id="clip-path-plannational">
        <Rect
          id="Rectángulo_430"
          data-name="Rectángulo 430"
          width="21"
          height="13.671"
          transform="translate(0 0)"
          fill="none"
          stroke="#707070"
          strokeWidth="1.5"
        />
      </ClipPath>
    </Defs>
    <Rect
      id="Rectángulo_428"
      data-name="Rectángulo 428"
      width="24"
      height="24"
      fill="#fff"
      opacity="0"
    />
    <G
      id="Grupo_926"
      data-name="Grupo 926"
      transform="translate(2 5)"
      clipPath="url(#clip-path-plannational)">
      <Path
        id="Trazado_82"
        data-name="Trazado 82"
        d="M18.223,10.757c1.91-1.063,1.712-2.859,1.609-4.6a2.246,2.246,0,0,1,.825-1.91C21.8,3.176,21.689,2.28,20.35,1.214L16.593,4.043c-.95-.373-.993-1.575-1.976-1.449a2.594,2.594,0,0,1-2.483-1.114,1.049,1.049,0,0,0-.8-.48C7.942,1,4.549,1,1,1,1,2.974,1,4.79,1,6.606c0,1.261,2.519,4.07,3.779,3.943,2.878-.291,5.351.265,7.083,2.82.506-.431.857-.717,1.193-1.02,1.212-1.092,1.677-1.107,2.947-.066a16.351,16.351,0,0,1,1.255,1.075,2.182,2.182,0,0,0,2.455.6C20.326,12.483,18.976,11.927,18.223,10.757Z"
        transform="translate(-0.72 -0.72)"
        fill="none"
        stroke="#1aa7ff"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
      <Path
        id="Trazado_83"
        data-name="Trazado 83"
        d="M18.223,10.757c.753,1.17,2.1,1.727,1.491,3.2a2.182,2.182,0,0,1-2.455-.6A16.351,16.351,0,0,0,16,12.282c-1.27-1.041-1.736-1.026-2.947.066-.336.3-.687.589-1.193,1.02-1.731-2.555-4.205-3.11-7.083-2.82C3.521,10.676,1.006,7.867,1,6.606,1,4.79,1,2.974,1,1c3.548,0,6.94,0,10.333,0a1.049,1.049,0,0,1,.8.48,2.594,2.594,0,0,0,2.483,1.114c.983-.126,1.027,1.076,1.976,1.449L20.35,1.214c1.339,1.066,1.448,1.963.307,3.036a2.246,2.246,0,0,0-.825,1.91C19.935,7.9,20.133,9.694,18.223,10.757Z"
        transform="translate(-0.72 -0.72)"
        fill="none"
        stroke="#1aa7ff"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
    </G>
  </Icon>
)

export default PlanNationalIcon
