import React from 'react'
import { Icon, IIconProps, useTheme } from 'native-base'
import { Path } from 'react-native-svg'

const WeatherLockIcon = ({ color, ...props }: IIconProps) => {
  const { colors } = useTheme() as any
  const fillColor = color || colors.text['50']
  return (
    <Icon width="20" height="20" viewBox="0 0 20 20"  {...props}>
      <Path
        d="M10 1.875C7.59766 1.875 5.625 3.84766 5.625 6.25V8.125H4.75C4.19772 8.125 3.75 8.57272 3.75 9.125V17.125C3.75 17.6773 4.19772 18.125 4.75 18.125H15.25C15.8023 18.125 16.25 17.6773 16.25 17.125V9.125C16.25 8.57272 15.8023 8.125 15.25 8.125H14.375V6.25C14.375 3.84766 12.4023 1.875 10 1.875ZM10 3.125C11.7212 3.125 13.125 4.52881 13.125 6.25V8.125H6.875V6.25C6.875 4.52881 8.27881 3.125 10 3.125ZM5 9.375H15V16.875H5V9.375Z"
        fill={fillColor}
      />
    </Icon>
  )
}
export default WeatherLockIcon
