import React from 'react'
import { Icon } from 'native-base'
import { Path } from 'react-native-svg'
import { ICustomIconProps } from '../interfaces'

const RadioSelect = ({
  color = "transparent" as any,
  ...props }: ICustomIconProps) => {

  return (
    <Icon
      width="36"
      height="36"
      viewBox="0 0 36 36"
      {...props}
      color="#333440">
      <Path
        d="M27.5 18C27.5 12.7533 23.2467 8.5 18 8.5C12.7533 8.5 8.5 12.7533 8.5 18C8.5 23.2467 12.7533 27.5 18 27.5C23.2467 27.5 27.5 23.2467 27.5 18Z"
        stroke="#207EED"
      />
      <Path
        d="M24 18C24 14.6863 21.3137 12 18 12C14.6863 12 12 14.6863 12 18C12 21.3137 14.6863 24 18 24C21.3137 24 24 21.3137 24 18Z"
        fill="#207EED"
      />
    </Icon>
  )
}
export default RadioSelect
