import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

const SignOutIcon = (props: IIconProps) => (
  <Icon width="21" height="20" viewBox="0 0 21 20" {...props}>
    <Path d="M18.4468 14.9118H16.8776C16.7704 14.9118 16.67 14.9587 16.603 15.0413C16.4468 15.231 16.2793 15.414 16.103 15.5881C15.3818 16.3101 14.5275 16.8854 13.5874 17.2823C12.6134 17.6937 11.5665 17.9048 10.5093 17.9029C9.44006 17.9029 8.40434 17.693 7.43113 17.2823C6.49101 16.8854 5.63672 16.3101 4.9155 15.5881C4.19299 14.8686 3.61688 14.0158 3.21907 13.077C2.80613 12.1038 2.59854 11.0703 2.59854 10.0011C2.59854 8.93188 2.80836 7.8984 3.21907 6.92519C3.61639 5.98546 4.18782 5.13947 4.9155 4.41403C5.64318 3.68858 6.48916 3.11715 7.43113 2.71983C8.40434 2.30912 9.44006 2.09929 10.5093 2.09929C11.5785 2.09929 12.6142 2.30688 13.5874 2.71983C14.5293 3.11715 15.3753 3.68858 16.103 4.41403C16.2793 4.59037 16.4445 4.7734 16.603 4.9609C16.67 5.04349 16.7726 5.09037 16.8776 5.09037H18.4468C18.5874 5.09037 18.6744 4.93412 18.5963 4.81581C16.8843 2.1551 13.8887 0.393937 10.4847 0.402866C5.13648 0.416259 0.848536 4.75778 0.902108 10.0993C0.955679 15.356 5.23693 19.5993 10.5093 19.5993C13.9043 19.5993 16.8865 17.8404 18.5963 15.1863C18.6722 15.068 18.5874 14.9118 18.4468 14.9118ZM20.4311 9.86046L17.2637 7.36046C17.1454 7.26671 16.9735 7.35153 16.9735 7.50108V9.19751H9.96461C9.86639 9.19751 9.78604 9.27787 9.78604 9.37608V10.6261C9.78604 10.7243 9.86639 10.8047 9.96461 10.8047H16.9735V12.5011C16.9735 12.6506 17.1476 12.7355 17.2637 12.6417L20.4311 10.1417C20.4525 10.125 20.4697 10.1037 20.4816 10.0793C20.4935 10.0549 20.4996 10.0282 20.4996 10.0011C20.4996 9.97398 20.4935 9.94723 20.4816 9.92287C20.4697 9.8985 20.4525 9.87716 20.4311 9.86046Z" />
  </Icon>
)

export default SignOutIcon
