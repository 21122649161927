import React from 'react'
import { Icon } from 'native-base'
import { Path } from 'react-native-svg'
import { ICustomIconProps } from '../interfaces'

const RadioUnselect = ({
  color = "transparent" as any,
  ...props }: ICustomIconProps) => {

  return (
    <Icon
      width="36"
      height="36"
      viewBox="0 0 36 36"
      {...props}
      color="#333440">
      <Path
        d="M28 18C28 12.4772 23.5228 8 18 8C12.4772 8 8 12.4772 8 18C8 23.5228 12.4772 28 18 28C23.5228 28 28 23.5228 28 18Z"
        stroke="#207EED"
      />
    </Icon>
  )

}
export default RadioUnselect
