import React from 'react'
import { Icon } from 'native-base'
import { Path } from 'react-native-svg'
import { ICustomIconProps } from '../interfaces'

const ResidenceProperty = ({
  fillColor = "#FFF",
  ...props }: ICustomIconProps) => (
  <Icon {...props} viewBox="0 0 30 30">
    <Path
      d="M10.05 15.3H8.6812C8.588 15.3 8.51245 15.3755 8.51245 15.4687V21.5062C8.51245 21.5994 8.588 21.675 8.6812 21.675H10.05C10.1431 21.675 10.2187 21.5994 10.2187 21.5062V15.4687C10.2187 15.3755 10.1431 15.3 10.05 15.3Z"
      fill={fillColor}
    />
    <Path
      d="M16.2375 17.325C16.2615 17.0481 16.2237 16.7694 16.1268 16.5089C16.0299 16.2484 15.8762 16.0128 15.6771 15.819C15.4779 15.6252 15.2381 15.4781 14.9751 15.3883C14.712 15.2986 14.4323 15.2684 14.1562 15.3H11.4375C11.4133 15.2934 11.3878 15.293 11.3634 15.2988C11.3389 15.3046 11.3164 15.3164 11.2977 15.3332C11.279 15.35 11.2649 15.3712 11.2566 15.3949C11.2482 15.4186 11.246 15.444 11.25 15.4688V21.4875C11.2469 21.5104 11.2491 21.5337 11.2565 21.5556C11.2639 21.5775 11.2762 21.5973 11.2926 21.6137C11.3089 21.63 11.3288 21.6424 11.3507 21.6497C11.3725 21.6571 11.3958 21.6594 11.4187 21.6563H12.7875C12.8104 21.6594 12.8337 21.6571 12.8556 21.6497C12.8774 21.6424 12.8973 21.63 12.9137 21.6137C12.93 21.5973 12.9423 21.5775 12.9497 21.5556C12.9571 21.5337 12.9593 21.5104 12.9562 21.4875V19.2938H13.2937L14.2875 21.5438C14.3034 21.5848 14.332 21.6196 14.3691 21.6432C14.4062 21.6669 14.4499 21.678 14.4937 21.675H15.9375C16.0687 21.675 16.1062 21.675 16.0687 21.4875L14.9437 19.125C15.3227 19.0015 15.6525 18.7603 15.8851 18.4367C16.1177 18.113 16.2412 17.7236 16.2375 17.325ZM13.875 17.9063H13.125V16.875H13.875C13.9587 16.8598 14.0448 16.8631 14.1272 16.8848C14.2095 16.9066 14.286 16.9461 14.3514 17.0006C14.4168 17.0552 14.4693 17.1234 14.5054 17.2005C14.5415 17.2777 14.5602 17.3618 14.5602 17.4469C14.5602 17.532 14.5415 17.6161 14.5054 17.6932C14.4693 17.7703 14.4168 17.8386 14.3514 17.8931C14.286 17.9477 14.2095 17.9872 14.1272 18.0089C14.0448 18.0306 13.9587 18.034 13.875 18.0188V17.9063Z"
      fill={fillColor}
    />
    <Path
      d="M20.6249 17.6063L21.9937 17.5125C22.1062 17.5125 22.1624 17.5125 22.1624 17.4188C22.1596 17.1037 22.0901 16.7927 21.9585 16.5064C21.8269 16.2201 21.6361 15.965 21.3988 15.7577C21.1614 15.5505 20.8828 15.3959 20.5814 15.304C20.28 15.2122 19.9625 15.1853 19.6499 15.225C19.3265 15.1903 18.9994 15.2249 18.6904 15.3266C18.3815 15.4283 18.0978 15.5947 17.8582 15.8148C17.6186 16.0348 17.4288 16.3034 17.3012 16.6026C17.1737 16.9018 17.1115 17.2248 17.1187 17.55V19.425C17.1091 19.75 17.1689 20.0733 17.2941 20.3734C17.4193 20.6735 17.6071 20.9434 17.8448 21.1652C18.0826 21.387 18.3649 21.5555 18.6729 21.6596C18.9809 21.7637 19.3076 21.8009 19.6312 21.7688C19.9448 21.8056 20.2626 21.7761 20.5641 21.682C20.8655 21.5879 21.1438 21.4314 21.3808 21.2228C21.6177 21.0141 21.8081 20.7578 21.9396 20.4707C22.071 20.1836 22.1406 19.872 22.1437 19.5563C22.1437 19.5563 22.1437 19.5563 21.9749 19.5563H20.6062C20.4937 19.5563 20.4187 19.5563 20.4187 19.65C20.4363 19.7649 20.429 19.8823 20.397 19.994C20.3651 20.1058 20.3094 20.2094 20.2337 20.2976C20.158 20.3858 20.0641 20.4566 19.9585 20.5052C19.8529 20.5537 19.738 20.5789 19.6218 20.5789C19.5056 20.5789 19.3907 20.5537 19.2851 20.5052C19.1794 20.4566 19.0856 20.3858 19.0099 20.2976C18.9342 20.2094 18.8785 20.1058 18.8465 19.994C18.8146 19.8823 18.8072 19.7649 18.8249 19.65V17.4938C18.8072 17.3789 18.8146 17.2615 18.8465 17.1498C18.8785 17.038 18.9342 16.9344 19.0099 16.8462C19.0856 16.758 19.1794 16.6872 19.2851 16.6386C19.3907 16.5901 19.5056 16.5649 19.6218 16.5649C19.738 16.5649 19.8529 16.5901 19.9585 16.6386C20.0641 16.6872 20.158 16.758 20.2337 16.8462C20.3094 16.9344 20.3651 17.038 20.397 17.1498C20.429 17.2615 20.4363 17.3789 20.4187 17.4938C20.4187 17.4938 20.4937 17.6063 20.6249 17.6063Z"
      fill={fillColor}
    />
    <Path
      d="M15 5.68127L3.63745 13.725V26.5313H21.2437V23.9063H6.26245V15.0938L15 8.88752L23.85 15.0938V26.5313H26.475V13.725L15 5.68127Z"
      fill={fillColor}
    />
  </Icon>
)

export default ResidenceProperty