import React from 'react'
import { Icon } from 'native-base'
import { Path } from 'react-native-svg'
import { ICustomIconProps } from '../interfaces'

const SalesTax = ({
  color = "#FFF" as any,
  ...props }: ICustomIconProps) => {
  const fillColor = color || "#fff" as any

  return (
    <Icon width="31" height="30" viewBox="0 0 31 30" {...props}>
      <Path d="M2.88125 15V17.5688H7.025V20.4188H9.59375V17.5688H11.4687C12.1865 17.5638 12.8733 17.2752 13.3791 16.7658C13.8849 16.2565 14.1688 15.5678 14.1687 14.85V13.125C14.1491 12.4237 13.8555 11.758 13.3507 11.2708C12.8459 10.7836 12.1703 10.5136 11.4687 10.5188H9.59375V8.64375H12.9875V6.0375H9.59375V3.1875H7.025V6.0375H5.15C4.4437 6.05222 3.77131 6.34317 3.27707 6.84793C2.78282 7.35269 2.50609 8.03106 2.50625 8.7375V10.425C2.50128 10.7778 2.56647 11.128 2.69805 11.4554C2.82963 11.7828 3.02496 12.0807 3.27269 12.332C3.52042 12.5832 3.81562 12.7827 4.14111 12.9188C4.46661 13.0549 4.81592 13.125 5.16875 13.125H7.04375V15H2.88125ZM9.59375 13.125H11.4687V15H9.59375V13.125ZM7.025 10.5188H5.15V8.64375H7.025V10.5188Z" fill={fillColor} />
      <Path d="M22.8875 9.35626H15.8938V11.9813H22.8875V9.35626Z" fill={fillColor} />
      <Path d="M23.675 3.1875H15.8938V5.8125H23.675C24.0962 5.81737 24.4988 5.98685 24.7966 6.28468C25.0944 6.58252 25.2639 6.98507 25.2688 7.40625V22.7625C25.2688 23.1852 25.1009 23.5906 24.802 23.8895C24.5031 24.1883 24.0977 24.3563 23.675 24.3563H7.02502V26.9813H23.675C24.7939 26.9813 25.867 26.5368 26.6581 25.7456C27.4493 24.9544 27.8938 23.8814 27.8938 22.7625V7.5C27.9063 6.93815 27.8064 6.37946 27.6 5.85675C27.3935 5.33404 27.0848 4.85783 26.6918 4.4561C26.2988 4.05437 25.8295 3.73521 25.3114 3.51736C24.7934 3.29951 24.237 3.18736 23.675 3.1875Z" fill={fillColor} />
      <Path d="M18.2 18.1125L18.575 18.4875L18.0875 19.1438V20.8688H20.7125V19.1625L20.2063 18.5063L20.5813 18.1313L21.2375 18.6188H22.9625V15.9938H21.2375L20.5813 16.5L20.2063 16.125L20.7125 15.4875V13.7625H18.0875V15.4688L18.5938 16.125L18.2188 16.5L17.5625 15.9938H15.8375V18.6188H17.5625L18.2 18.1125Z" fill={fillColor} />
    </Icon>
  )

}
export default SalesTax
