import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

const QuestionMarkOutlinedIcon = (props: IIconProps) => (
  <Icon width="18" height="18" viewBox="0 0 18 18" {...props}>
    <Path d="M9 0.25C4.16797 0.25 0.25 4.16797 0.25 9C0.25 13.832 4.16797 17.75 9 17.75C13.832 17.75 17.75 13.832 17.75 9C17.75 4.16797 13.832 0.25 9 0.25ZM9 16.2656C4.98828 16.2656 1.73438 13.0117 1.73438 9C1.73438 4.98828 4.98828 1.73438 9 1.73438C13.0117 1.73438 16.2656 4.98828 16.2656 9C16.2656 13.0117 13.0117 16.2656 9 16.2656Z" />
    <Path d="M11.1794 5.18494C10.5934 4.67127 9.82001 4.39001 8.99969 4.39001C8.17938 4.39001 7.40595 4.67322 6.82001 5.18494C6.21063 5.71814 5.8747 6.43494 5.8747 7.20252V7.35095C5.8747 7.43689 5.94501 7.5072 6.03095 7.5072H6.96845C7.05438 7.5072 7.1247 7.43689 7.1247 7.35095V7.20252C7.1247 6.34119 7.96649 5.64002 8.99969 5.64002C10.0329 5.64002 10.8747 6.34119 10.8747 7.20252C10.8747 7.80994 10.445 8.36658 9.77899 8.62244C9.36493 8.78064 9.01337 9.05798 8.76141 9.42127C8.50556 9.79236 8.37274 10.2377 8.37274 10.6888V11.1088C8.37274 11.1947 8.44306 11.265 8.52899 11.265H9.46649C9.55243 11.265 9.62274 11.1947 9.62274 11.1088V10.6654C9.62375 10.4758 9.68188 10.2909 9.78953 10.1348C9.89719 9.97873 10.0494 9.85871 10.2263 9.79041C11.3786 9.34705 12.1227 8.33142 12.1227 7.20252C12.1247 6.43494 11.7888 5.71814 11.1794 5.18494ZM8.21845 13.2963C8.21845 13.5035 8.30076 13.7022 8.44727 13.8487C8.59378 13.9952 8.79249 14.0775 8.99969 14.0775C9.2069 14.0775 9.40561 13.9952 9.55212 13.8487C9.69864 13.7022 9.78094 13.5035 9.78094 13.2963C9.78094 13.0891 9.69864 12.8904 9.55212 12.7438C9.40561 12.5973 9.2069 12.515 8.99969 12.515C8.79249 12.515 8.59378 12.5973 8.44727 12.7438C8.30076 12.8904 8.21845 13.0891 8.21845 13.2963Z" />
  </Icon>
)

export default QuestionMarkOutlinedIcon
