import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

const UserIcon = (props: IIconProps) => (
  <Icon width="18" height="18" viewBox="0 0 18 18" {...props}>
    <Path
      d="M16.7337 14.6133C16.3127 13.6159 15.7016 12.7099 14.9346 11.9459C14.17 11.1796 13.2642 10.5687 12.2672 10.1468C12.2583 10.1423 12.2494 10.1401 12.2404 10.1356C13.6311 9.13114 14.5351 7.49498 14.5351 5.649C14.5351 2.59096 12.0574 0.113281 8.99936 0.113281C5.94132 0.113281 3.46364 2.59096 3.46364 5.649C3.46364 7.49498 4.36766 9.13114 5.75829 10.1378C5.74936 10.1423 5.74043 10.1445 5.7315 10.149C4.7315 10.5709 3.83418 11.1758 3.06409 11.9481C2.29782 12.7128 1.68687 13.6186 1.26498 14.6155C0.850524 15.5915 0.626997 16.6379 0.606501 17.6981C0.605905 17.7219 0.610084 17.7456 0.618791 17.7678C0.627498 17.79 0.640557 17.8102 0.657198 17.8273C0.67384 17.8443 0.693728 17.8579 0.715689 17.8671C0.73765 17.8764 0.761242 17.8811 0.785073 17.8811H2.12436C2.22257 17.8811 2.3007 17.803 2.30293 17.707C2.34757 15.9838 3.03954 14.37 4.26275 13.1468C5.52838 11.8811 7.20918 11.1847 8.99936 11.1847C10.7895 11.1847 12.4703 11.8811 13.736 13.1468C14.9592 14.37 15.6511 15.9838 15.6958 17.707C15.698 17.8052 15.7761 17.8811 15.8744 17.8811H17.2136C17.2375 17.8811 17.2611 17.8764 17.283 17.8671C17.305 17.8579 17.3249 17.8443 17.3415 17.8273C17.3582 17.8102 17.3712 17.79 17.3799 17.7678C17.3886 17.7456 17.3928 17.7219 17.3922 17.6981C17.3699 16.6311 17.1489 15.5932 16.7337 14.6133V14.6133ZM8.99936 9.48828C7.9748 9.48828 7.01052 9.08873 6.28507 8.36328C5.55963 7.63784 5.16007 6.67355 5.16007 5.649C5.16007 4.62444 5.55963 3.66016 6.28507 2.93471C7.01052 2.20926 7.9748 1.80971 8.99936 1.80971C10.0239 1.80971 10.9882 2.20926 11.7136 2.93471C12.4391 3.66016 12.8386 4.62444 12.8386 5.649C12.8386 6.67355 12.4391 7.63784 11.7136 8.36328C10.9882 9.08873 10.0239 9.48828 8.99936 9.48828Z"
    />
  </Icon>
)

export default UserIcon
