const numberCeiling = `function numberCeiling(value, factor = 10) {
  const parsedValue = parseFloat(value)
  const result =
    parsedValue > 0
      ? Math.ceil(((parsedValue + Number.EPSILON) * factor) / factor)
      : 0
  return result
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    .replace(/\.\0+$/, '')
}`

const formatNumber = `function formatNumber(
  value,
  decimalPlacesParam,
  factor = 10,
) {
  const decimalPlacesByFactor = factor === 10 ? 1 : 2
  const decimalPlaces =
    decimalPlacesParam !== null ? decimalPlacesParam : decimalPlacesByFactor
  const result = (
    ((parseFloat(value) + Number.EPSILON) * factor) /
    factor
  ).toFixed(decimalPlaces)
  return result
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    .replace(/\.0+$/, '')
}`

export default function (templateString: string = '', data: any) {
  const functionToExecute = `
  (() => {
    const data = JSON.parse(\`${JSON.stringify(data)}\`);
    ${numberCeiling};
    ${formatNumber};
    return \`${templateString}\`;
  })()`
  const result = eval(functionToExecute)
  return result
}
