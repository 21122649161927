import { Icon, IIconProps, useTheme } from 'native-base'
import { Path } from 'react-native-svg'

const ExternalLinkIcon = ({ color, ...props }: IIconProps) => {
  const { colors } = useTheme()
  const fillColor = color || colors.primary['500']

  return (
    <Icon width="16" height="17" viewBox="0 0 16 17"  {...props}>
      <Path
        d="M13.7139 0.319092H4.14244C4.06387 0.319092 3.99958 0.383378 3.99958 0.461949V1.46195C3.99958 1.54052 4.06387 1.60481 4.14244 1.60481H12.9996V13.8905C12.9996 13.9691 13.0639 14.0334 13.1424 14.0334H14.1424C14.221 14.0334 14.2853 13.9691 14.2853 13.8905V0.89052C14.2853 0.574449 14.0299 0.319092 13.7139 0.319092ZM11.4282 2.60481H2.2853C1.96922 2.60481 1.71387 2.86016 1.71387 3.17623V12.653C1.71387 12.8048 1.77458 12.9494 1.88172 13.0566L4.97637 16.1512C5.01565 16.1905 5.0603 16.2227 5.10851 16.2494V16.2834H5.18351C5.24601 16.3066 5.31208 16.3191 5.37994 16.3191H11.4282C11.7442 16.3191 11.9996 16.0637 11.9996 15.7477V3.17623C11.9996 2.86016 11.7442 2.60481 11.4282 2.60481ZM5.10672 14.4655L3.56922 12.9262H5.10672V14.4655ZM10.7139 15.0334H6.24958V12.4977C6.24958 12.103 5.92994 11.7834 5.5353 11.7834H2.99958V3.89052H10.7139V15.0334Z"
        fill={fillColor}
      />
    </Icon>
  )
}

export default ExternalLinkIcon
