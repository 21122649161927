import React from 'react'
import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

const WasteCalculatorIcon = (props: IIconProps) => (
  <Icon {...props} viewBox="0 0 25 25">
    <Path
      d="M20.0112 5.31466H5.04484V10.4183H20.0112V5.31466Z"
      fill="#207EED"
    />
    <Path
      d="M20.0673 1.31069e-10H4.93274C3.62772 -9.61355e-06 2.37589 0.531013 1.45135 1.47678C0.526822 2.42255 0.00494323 3.70599 0 5.04605V19.9348C0.00492181 21.2766 0.5262 22.562 1.4502 23.5108C2.37421 24.4597 3.62601 24.9949 4.93274 25H20.0673C21.3755 25 22.6302 24.4663 23.5552 23.5164C24.4803 22.5665 25 21.2781 25 19.9348V5.04605C24.9951 3.70599 24.4732 2.42255 23.5486 1.47678C22.6241 0.531013 21.3723 -9.61355e-06 20.0673 1.31069e-10ZM22.4215 19.9348C22.4216 20.2505 22.3604 20.563 22.2417 20.8542C22.1229 21.1453 21.9489 21.4093 21.7298 21.6308C21.5106 21.8522 21.2508 22.0266 20.9654 22.1439C20.68 22.2612 20.3747 22.319 20.0673 22.3139H4.93274C4.31977 22.3089 3.73329 22.0566 3.29984 21.6115C2.8664 21.1664 2.62073 20.5642 2.61584 19.9348V5.04605C2.62077 4.41839 2.86705 3.81817 3.30102 3.37614C3.73499 2.93411 4.32148 2.68609 4.93274 2.68611H20.0673C20.3706 2.68358 20.6715 2.74275 20.9525 2.86021C21.2335 2.97768 21.489 3.15111 21.7044 3.3705C21.9198 3.58989 22.0908 3.85091 22.2074 4.13848C22.3241 4.42606 22.3842 4.73451 22.3842 5.04605L22.4215 19.9348Z"
      fill="#207EED"
    />
    <Path
      d="M5.96039 19.0906L4.42825 13.8335C4.42825 13.7183 4.42825 13.68 4.55904 13.68H5.81091C5.84789 13.6741 5.88567 13.6826 5.91681 13.7039C5.94795 13.7252 5.97016 13.7578 5.97907 13.7951L6.61435 16.2893L7.19357 13.7951C7.19588 13.7749 7.20208 13.7553 7.21181 13.7376C7.22154 13.7198 7.23462 13.7043 7.25027 13.6918C7.26591 13.6793 7.28383 13.6701 7.30297 13.6648C7.3221 13.6595 7.34208 13.6581 7.36173 13.6608H8.25859C8.29557 13.6549 8.33336 13.6634 8.36449 13.6847C8.39563 13.706 8.41785 13.7386 8.42676 13.7759L9.11808 16.2893L9.69731 13.7951C9.70188 13.7578 9.71963 13.7235 9.74718 13.6988C9.77473 13.674 9.81018 13.6605 9.84679 13.6608H11.0987C11.2108 13.6608 11.2481 13.6608 11.2294 13.8143L9.88415 19.0714C9.87554 19.1103 9.85408 19.1449 9.82343 19.1694C9.79277 19.1938 9.75481 19.2067 9.71599 19.2057H8.6136C8.59395 19.2084 8.57397 19.207 8.55483 19.2017C8.5357 19.1964 8.51778 19.1872 8.50213 19.1747C8.48648 19.1622 8.47341 19.1466 8.46368 19.1289C8.45395 19.1111 8.44775 19.0916 8.44544 19.0714L7.90359 16.4428L7.43647 19.0714C7.43417 19.0916 7.42797 19.1111 7.41823 19.1289C7.4085 19.1466 7.39543 19.1622 7.37978 19.1747C7.36413 19.1872 7.34621 19.1964 7.32708 19.2017C7.30794 19.207 7.28796 19.2084 7.26831 19.2057H6.12855C6.09216 19.2078 6.05608 19.1977 6.02575 19.177C5.99542 19.1562 5.97248 19.1259 5.96039 19.0906Z"
      fill="#207EED"
    />
    <Path
      d="M11.6031 17.4981C11.6031 17.4021 11.6031 17.3638 11.7526 17.3638H12.8924C12.9858 17.3638 13.0232 17.3638 13.0232 17.3638C13.0232 17.6324 13.3408 17.9394 13.8266 17.9394C14.3124 17.9394 14.3498 17.6899 14.3498 17.5365C14.3498 17.383 13.9761 17.1719 13.4155 16.9992C12.855 16.8266 11.5471 16.3469 11.5471 15.2149C11.5471 14.0829 12.4253 13.5265 13.565 13.5265C13.8328 13.4847 14.1062 13.5033 14.3664 13.5808C14.6265 13.6584 14.8671 13.7931 15.0715 13.9756C15.2758 14.1581 15.4391 14.384 15.5501 14.6378C15.661 14.8915 15.7168 15.167 15.7137 15.4451C15.7169 15.466 15.7153 15.4874 15.7089 15.5075C15.7025 15.5277 15.6916 15.5459 15.677 15.5609C15.6624 15.5759 15.6446 15.5871 15.625 15.5936C15.6055 15.6002 15.5846 15.6019 15.5643 15.5986H14.3124C14.3124 15.3108 14.0321 14.9846 13.5277 14.9846C13.0232 14.9846 13.0045 15.1381 13.0045 15.3684C13.0045 15.5986 13.2848 15.7713 14.0882 16.0399C14.8916 16.3085 15.7698 16.462 15.7698 17.6708C15.7698 18.8795 14.9103 19.3784 13.6771 19.3784C12.4439 19.3784 11.6031 18.5917 11.6031 17.4981Z"
      fill="#207EED"
    />
    <Path
      d="M20.6091 13.8143V14.812C20.6091 14.812 20.6091 14.9463 20.4783 14.9463H19.2265V18.9946C19.2296 19.0155 19.228 19.0369 19.2216 19.057C19.2152 19.0772 19.2043 19.0954 19.1897 19.1104C19.1752 19.1254 19.1573 19.1366 19.1378 19.1431C19.1182 19.1497 19.0973 19.1514 19.077 19.1481H17.9185C17.8982 19.1514 17.8774 19.1497 17.8578 19.1431C17.8382 19.1366 17.8204 19.1254 17.8058 19.1104C17.7912 19.0954 17.7803 19.0772 17.7739 19.057C17.7675 19.0369 17.7659 19.0155 17.7691 18.9946V15.023H16.5546C16.4611 15.023 16.4051 15.023 16.4051 14.8887V13.8143C16.4051 13.7183 16.4051 13.68 16.5546 13.68H20.5904C20.5904 13.68 20.6091 13.7183 20.6091 13.8143Z"
      fill="#207EED"
    />
  </Icon>
)

export default WasteCalculatorIcon
