import React, { forwardRef } from 'react'
import { TextArea } from 'native-base'
import { IFormControlProps } from 'native-base/lib/typescript/components/composites/FormControl'
import { ITextAreaProps } from 'native-base/lib/typescript/components/primitives/TextArea'

import Base, { IIsolatedBaseProps, NativeBaseRef } from './Base.field'

export interface IFormTextAreaProps extends ITextAreaProps, IIsolatedBaseProps {
  labelProps?: IFormControlProps
  showInputRightElementAsSuffix?: boolean
}

const FormTextArea = forwardRef<any, IFormTextAreaProps>(
  (
    {
      value,
      onChange,
      label,
      showAsOptional,
      showAsRequired,
      showTooltip,
      tooltipProps,
      error,
      labelProps = {},
      showInputRightElementAsSuffix = false,
      InputRightElement,
      ...rest
    }: IFormTextAreaProps,
    ref,
  ) => (
    <Base
      label={label}
      showAsOptional={showAsOptional}
      error={error}
      showAsRequired={showAsRequired}
      tooltipProps={tooltipProps}
      showTooltip={showTooltip}
      InputRightElement={
        showInputRightElementAsSuffix ? InputRightElement : undefined
      }
      {...labelProps}>
      <TextArea
        ref={ref as NativeBaseRef}
        InputRightElement={
          showInputRightElementAsSuffix ? undefined : InputRightElement
        }
        onChange={onChange}
        value={value}
        isInvalid={!!error}
        {...rest}
      />
    </Base>
  ),
)

export default FormTextArea
