import React from 'react'
import { Icon, IIconProps, useTheme } from 'native-base'
import { Path } from 'react-native-svg'

const HouseIcon = ({ color, ...props }: IIconProps) => {
  const { colors } = useTheme() as any
  const fillColor = color || colors.secondary['500']
  return (
    <Icon {...props} width="24" height="24" viewBox="0 0 24 24">
      <Path
        d="M20.6668 11.7603L12.0062 4L7.25022 8.25685V6.42352H5.5473V9.77626L3.3335 11.7603L4.45256 13.0913L5.5473 12.1119V18.6667H10.9967V13.9578H13.0158V18.6416H18.453V12.0993L19.5599 13.0913L20.6668 11.7603ZM16.7988 16.8836H14.7188V12.1998H9.29373V16.9087H7.25022V10.6301L12.0062 6.33562L16.7988 10.5799V16.8836Z"
        fill={fillColor}
      />
    </Icon>
  )
}

export default HouseIcon
