import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

const DocumentIcon = (props: IIconProps) => (
  <Icon width="16" height="20" viewBox="0 0 16 20" {...props}>
    <Path d="M15.6468 5.01339L10.8432 0.209821C10.7093 0.0758929 10.5285 0 10.3388 0H0.85662C0.46153 0 0.142334 0.319196 0.142334 0.714286V19.2857C0.142334 19.6808 0.46153 20 0.85662 20H15.1423C15.5374 20 15.8566 19.6808 15.8566 19.2857V5.52009C15.8566 5.33036 15.7807 5.14732 15.6468 5.01339ZM14.2093 5.84821H10.0084V1.64732L14.2093 5.84821ZM14.2495 18.3929H1.74948V1.60714H8.49055V6.42857C8.49055 6.67721 8.58932 6.91567 8.76514 7.09148C8.94095 7.2673 9.17941 7.36607 9.42805 7.36607H14.2495V18.3929ZM7.82091 12.3661H3.71376C3.61555 12.3661 3.53519 12.4464 3.53519 12.5446V13.6161C3.53519 13.7143 3.61555 13.7946 3.71376 13.7946H7.82091C7.91912 13.7946 7.99948 13.7143 7.99948 13.6161V12.5446C7.99948 12.4464 7.91912 12.3661 7.82091 12.3661ZM3.53519 9.50893V10.5804C3.53519 10.6786 3.61555 10.7589 3.71376 10.7589H12.2852C12.3834 10.7589 12.4638 10.6786 12.4638 10.5804V9.50893C12.4638 9.41071 12.3834 9.33036 12.2852 9.33036H3.71376C3.61555 9.33036 3.53519 9.41071 3.53519 9.50893Z" />
  </Icon>
)

export default DocumentIcon
