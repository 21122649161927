import React from 'react'
import { Icon, IIconProps, useTheme } from 'native-base'
import { Path } from 'react-native-svg'

const DetailIcon = ({ color, ...props }: IIconProps) => {
  const { colors } = useTheme() as any
  const fillColor = color || colors.secondary['500']
  return (
    <Icon {...props} width="24" height="24" viewBox="0 0 24 24">
      <Path
        d="M14.7381 9.68871H9.07383V11.516H14.7381V9.68871Z"
        fill={fillColor}
      />
      <Path
        d="M11.8993 13.1606H9.07383V14.9879H11.8993V13.1606Z"
        fill={fillColor}
      />
      <Path
        d="M16.7832 5.10739V17.6897C16.7798 17.8168 16.7263 17.9377 16.6336 18.0275C16.541 18.1174 16.4164 18.1693 16.2854 18.1727H7.71493C7.5829 18.1727 7.45628 18.1218 7.36292 18.0312C7.26956 17.9406 7.21712 17.8178 7.21712 17.6897V5.10739C6.71755 5.10739 6.23844 5.29991 5.8852 5.6426C5.53195 5.98528 5.3335 6.45007 5.3335 6.9347V17.6897C5.3335 18.3024 5.5844 18.8901 6.031 19.3233C6.47761 19.7566 7.08333 20 7.71493 20H16.2854C16.917 20 17.5227 19.7566 17.9693 19.3233C18.4159 18.8901 18.6668 18.3024 18.6668 17.6897V6.9347C18.6668 6.45007 18.4684 5.98528 18.1151 5.6426C17.7619 5.29991 17.2828 5.10739 16.7832 5.10739Z"
        fill={fillColor}
      />
      <Path
        d="M13.608 5.10739H12.6123C12.8403 5.00826 13.0353 4.84952 13.1755 4.64897C13.3157 4.44841 13.3955 4.21395 13.4061 3.97185C13.4061 3.62568 13.2644 3.29369 13.0121 3.04892C12.7598 2.80414 12.4175 2.66663 12.0607 2.66663C11.7039 2.66663 11.3617 2.80414 11.1093 3.04892C10.857 3.29369 10.7153 3.62568 10.7153 3.97185C10.7209 4.20842 10.7928 4.43905 10.9231 4.63907C11.0534 4.83909 11.2374 5.00097 11.4553 5.10739H10.4193C9.87434 5.10766 9.34599 5.28922 8.92239 5.62177C8.49879 5.95432 8.20556 6.41774 8.09165 6.9347H15.9356C15.8194 6.41886 15.5255 5.9568 15.1024 5.62464C14.6793 5.29249 14.1522 5.11005 13.608 5.10739Z"
        fill={fillColor}
      />
    </Icon>
  )
}

export default DetailIcon
