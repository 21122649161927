import Svg, { ClipPath, Defs, G, Path, SvgProps } from 'react-native-svg'
export const LogoSoloLightSVG = (props: SvgProps) => (
  <Svg viewBox="0 0 53 68" fill="none" {...props}>
    <G clipPath="url(#a)">
      <Path
        fill="#FEAC01"
        d="M29.617 26.744c-5.194 0-9.416 4.21-9.416 9.404 0 5.193 9.416 16.446 9.416 16.446s9.404-11.241 9.404-16.446a9.397 9.397 0 0 0-9.404-9.405Zm0 12.188a2.78 2.78 0 0 1-2.773-2.784 2.77 2.77 0 0 1 2.773-2.772 2.778 2.778 0 0 1 2.772 2.772c0 1.52-1.24 2.784-2.772 2.784ZM52.894 5.128 44.495.25v67h8.399V5.128Z"
      />
      <Path
        fill="#22292D"
        d="M23.803 3.046h-8.386v13.018L0 24.814v9.661l37.301-21.183v-9.65l-13.498 7.662V3.046ZM14.212 34.3l-8.387 4.76v28.19h31.476v-8.387h-23.09V34.3Z"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 .25h53v67H0z" />
      </ClipPath>
    </Defs>
  </Svg>
)
export default LogoSoloLightSVG
