import React from 'react'
import { Icon } from 'native-base'
import { G, Rect, Defs, Path, ClipPath } from 'react-native-svg'

import { PlanType } from '../types'

const PlanOneStateIcon = (props: PlanType) => (
  <Icon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <Defs>
      <ClipPath id="clip-path-planonestate">
        <Rect
          id="Rectángulo_440"
          data-name="Rectángulo 440"
          width="18.465"
          height="21.498"
          fill="none"
        />
      </ClipPath>
    </Defs>
    <Rect
      id="Rectángulo_428"
      data-name="Rectángulo 428"
      width="24"
      height="24"
      transform="translate(0 0)"
      fill="#fff"
      opacity="0"
    />
    <G id="Grupo_950" data-name="Grupo 950" transform="translate(-16816 -1679)">
      <Rect
        id="Rectángulo_431"
        data-name="Rectángulo 431"
        width="24"
        height="24"
        transform="translate(16816 1679)"
        fill="#fff"
        opacity="0"
      />
      <G id="Grupo_951" data-name="Grupo 951" transform="translate(16819 1680)">
        <G
          id="Grupo_951-2"
          data-name="Grupo 951"
          transform="translate(0 0)"
          clipPath="url(#clip-path-planonestate)">
          <Path
            id="Trazado_84"
            data-name="Trazado 84"
            d="M15.275,11.768a5.084,5.084,0,0,1-1.034-.106,23.317,23.317,0,0,1-1.975,3.388,21.392,21.392,0,0,1-3.668,4.2,21.4,21.4,0,0,1-3.667-4.2c-1.786-2.571-2.9-5.077-2.9-6.54a6.567,6.567,0,0,1,9.839-5.6,5.049,5.049,0,0,1,2-1.108A8.608,8.608,0,0,0,0,8.51C0,13.21,6.876,21.5,8.6,21.5c1.386,0,6.091-5.351,7.889-9.892a5.076,5.076,0,0,1-1.213.161"
            transform="translate(0 0)"
            fill="#1aa7ff"
          />
          <Path
            id="Trazado_85"
            data-name="Trazado 85"
            d="M18.248,6.613a3.19,3.19,0,1,1-3.19-3.19,3.191,3.191,0,0,1,3.19,3.19"
            transform="translate(0.217 0.063)"
            fill="#fcb627"
          />
        </G>
      </G>
    </G>
  </Icon>
)

export default PlanOneStateIcon
