import React from 'react'
import _ from 'lodash'
import { Icon, IIconProps, useTheme } from 'native-base'
import { G, Rect } from 'react-native-svg'

const CreditCardIcon = ({
  bg,
  backgroundColor,
  bgColor,
  color,
  ...props
}: IIconProps) => {
  const { colors } = useTheme() as any
  const finalBg = _.get(
    colors,
    (backgroundColor || bgColor || bg) as string,
    colors.background['600'],
  )

  const finalColor = color || colors.text['500']

  return (
    <Icon width="32" height="21.053" viewBox="0 0 32 21.053" {...props}>
      <G fillRule="nonzero" stroke="none" strokeWidth={1} fill="none">
        <Rect width="32" height="21.053" rx="2" fill={finalColor} />
        <Rect
          width="10"
          height="9"
          transform="translate(4 3.013)"
          fill={finalBg}
        />
        <Rect
          width="5"
          height="2"
          transform="translate(4 14.082)"
          fill={finalBg}
        />
        <Rect
          width="5"
          height="2"
          transform="translate(10 14.082)"
          fill={finalBg}
        />
        <Rect
          width="5"
          height="2"
          transform="translate(16 14.082)"
          fill={finalBg}
        />
        <Rect
          width="5"
          height="2"
          transform="translate(22 14.082)"
          fill={finalBg}
        />
      </G>
    </Icon>
  )
}

export default CreditCardIcon
