import React from 'react'
import { Icon, IIconProps, useTheme } from 'native-base'
import { Path } from 'react-native-svg'

const ContactOutlinedIcon = ({ color, ...props }: IIconProps) => {
  const { colors } = useTheme() as any
  const fillColor = color || colors.text['50']
  return (
    <Icon viewBox="0 0 20 16" color={fillColor} {...props}>
      <Path
        fill="currentColor"
        d="M11.837 9.994c.42-.461.651-1.063.65-1.686 0-1.381-1.114-2.5-2.487-2.5a2.493 2.493 0 0 0-2.487 2.5 2.5 2.5 0 0 0 .65 1.686 3.53 3.53 0 0 0-1.665 2.814.179.179 0 0 0 .178.188h.98c.094 0 .17-.074.177-.168a2.183 2.183 0 0 1 2.17-2.02c1.142 0 2.084.893 2.169 2.02a.178.178 0 0 0 .176.168h.978a.18.18 0 0 0 .167-.117.178.178 0 0 0 .011-.07 3.528 3.528 0 0 0-1.667-2.815ZM10 9.469c-.636 0-1.154-.52-1.154-1.16 0-.641.518-1.161 1.154-1.161.636 0 1.154.52 1.154 1.16 0 .64-.518 1.161-1.154 1.161Zm9.286-7.902h-3.572V.317a.18.18 0 0 0-.178-.178h-1.25a.18.18 0 0 0-.179.178v1.25h-3.303V.317a.18.18 0 0 0-.179-.178h-1.25a.18.18 0 0 0-.179.178v1.25H5.893V.317A.18.18 0 0 0 5.714.14h-1.25a.18.18 0 0 0-.178.178v1.25H.714A.714.714 0 0 0 0 2.282v12.857c0 .395.32.714.714.714h18.572c.395 0 .714-.32.714-.714V2.282a.714.714 0 0 0-.714-.715Zm-.893 12.679H1.607V3.174h2.679v1.25c0 .099.08.179.178.179h1.25a.18.18 0 0 0 .179-.179v-1.25h3.303v1.25c0 .099.08.179.179.179h1.25a.18.18 0 0 0 .179-.179v-1.25h3.303v1.25c0 .099.08.179.179.179h1.25a.18.18 0 0 0 .178-.179v-1.25h2.679v11.072Z"
      />
    </Icon>
  )
}

export default ContactOutlinedIcon
