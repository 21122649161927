import React from 'react'
import { Icon, IIconProps, useTheme } from 'native-base'
import { Path } from 'react-native-svg'

const EmailOutlinedIcon = ({ color, ...props }: IIconProps) => {
  const { colors } = useTheme() as any
  const fillColor = color || colors.text['50']
  return (
    <Icon viewBox="0 0 18 19" color={fillColor} {...props}>
      <Path
        fill="currentColor"
        d="M3.938 3.75h10.124c1.3 0 2.362 1.017 2.434 2.3l.004.138v7.125c0 1.3-1.017 2.361-2.3 2.433l-.137.004H3.937a2.438 2.438 0 0 1-2.433-2.3l-.004-.137V6.186c0-1.3 1.017-2.361 2.3-2.433l.138-.004Zm11.437 4.03-6.113 3.218a.563.563 0 0 1-.452.032l-.072-.032L2.625 7.78v5.532c0 .688.53 1.253 1.205 1.308l.107.004h10.126c.688 0 1.253-.53 1.308-1.205l.004-.107V7.78Zm-1.313-2.905H3.938c-.69 0-1.254.53-1.309 1.205l-.004.107v.322L9 9.864l6.375-3.356v-.32c0-.69-.53-1.254-1.205-1.309l-.107-.004Z"
      />
    </Icon>
  )
}

export default EmailOutlinedIcon
