import { ColorType } from 'native-base/lib/typescript/components/types'

import { default as colorsImport } from './colors'

export interface IThemeColorPalette {
  primary: ColorType
  secondary: ColorType
  purple: ColorType
  // UI
  appBg: ColorType
  appBrandGold: ColorType
  appSecondaryBg: ColorType
  appContentBg: ColorType
  appContentSecondaryBg: ColorType
  appBorder: ColorType
  // Text colors
  text: ColorType
  textInverse: ColorType
  primaryText: ColorType
  secondaryText: ColorType
  error: ColorType
  textMuted: ColorType

  // Toolbar default and active colors
  barText: ColorType
  barSelected: ColorType
  barBg: ColorType

  // Form colors
  input: ColorType
  inputBg: ColorType
  inputBorder: ColorType
  inputText: ColorType
  inputDisabled: ColorType
  inputDisabledBg: ColorType
  inputSelection: ColorType
  // Label colors
  label: ColorType
  labelBg: ColorType
  // Button colors
  // Primary button
  primaryButton: ColorType
  primaryButtonBg: ColorType
  primaryButtonText: ColorType
  primaryButtonBorder: ColorType
  // Secondry button
  secondaryButton: ColorType
  secondaryButtonBg: ColorType
  secondaryButtonText: ColorType
  secondaryButtonBorder: ColorType
  // Danger button
  dangerButton: ColorType
  dangerButtonBg: ColorType
  dangerButtonText: ColorType
  dangerButtonBorder: ColorType
  // Link button
  linkButton: ColorType
  linkButtonBg: ColorType
  linkButtonText: ColorType
  linkButtonBorder: ColorType
}

interface ThemeColorDefinition {
  darkTheme: IThemeColorPalette
  lightTheme: IThemeColorPalette
}

export const colors: ThemeColorDefinition = colorsImport as ThemeColorDefinition
