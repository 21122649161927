import React from 'react'
import { Icon } from 'native-base'
import { Path } from 'react-native-svg'
import { ICustomIconProps } from '../interfaces'

const LockIcon = ({
  fillColor = "#F0A104",
  ...props }: ICustomIconProps) => (
  <Icon {...props} viewBox="0 0 16 16">
    <Path
      d="M13.3801 13.0502V6.78015C13.3775 6.41402 13.2316 6.06347 12.9737 5.80364C12.7157 5.54381 12.3662 5.39538 12.0001 5.39015H10.6901V3.70015C10.6822 3.12637 10.4473 2.57908 10.0369 2.17802C9.6265 1.77697 9.07393 1.55481 8.50012 1.56015H7.50012C6.92536 1.55478 6.37173 1.77663 5.95972 2.1774C5.5477 2.57818 5.31064 3.12546 5.30012 3.70015V5.39015H4.30012C3.89732 5.3712 3.50194 5.50314 3.19126 5.7602C2.88057 6.01725 2.67692 6.38094 2.62012 6.78015V13.0502C2.62008 13.418 2.76483 13.7711 3.02307 14.0331C3.28131 14.2951 3.63229 14.4449 4.00012 14.4502H12.0001C12.3679 14.4449 12.7189 14.2951 12.9772 14.0331C13.2354 13.7711 13.3802 13.418 13.3801 13.0502ZM11.7601 6.85015V12.8502H4.19012V6.85015H11.7601ZM6.70012 3.70015C6.71793 3.50301 6.81098 3.3203 6.95995 3.18996C7.10891 3.05961 7.30236 2.99164 7.50012 3.00015H8.50012C8.70268 2.99474 8.8992 3.06939 9.04709 3.20791C9.19497 3.34644 9.28229 3.53767 9.29012 3.74015V5.39015H6.70012V3.70015Z"
      fill={fillColor}
    />
  </Icon>
)

export default LockIcon