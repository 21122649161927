import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

const ProjectIcon = (props: IIconProps) => (
  <Icon width="20" height="20" viewBox="0 0 20 20" {...props}>
    <Path d="M19.8214 9.15179H17.0938C16.7076 5.88393 14.1161 3.29241 10.8482 2.90625V0.178571C10.8482 0.0803571 10.7679 0 10.6696 0H9.33036C9.23214 0 9.15179 0.0803571 9.15179 0.178571V2.90625C5.88393 3.29241 3.29241 5.88393 2.90625 9.15179H0.178571C0.0803571 9.15179 0 9.23214 0 9.33036V10.6696C0 10.7679 0.0803571 10.8482 0.178571 10.8482H2.90625C3.29241 14.1161 5.88393 16.7076 9.15179 17.0938V19.8214C9.15179 19.9196 9.23214 20 9.33036 20H10.6696C10.7679 20 10.8482 19.9196 10.8482 19.8214V17.0938C14.1161 16.7076 16.7076 14.1161 17.0938 10.8482H19.8214C19.9196 10.8482 20 10.7679 20 10.6696V9.33036C20 9.23214 19.9196 9.15179 19.8214 9.15179ZM10 15.4464C6.99107 15.4464 4.55357 13.0089 4.55357 10C4.55357 6.99107 6.99107 4.55357 10 4.55357C13.0089 4.55357 15.4464 6.99107 15.4464 10C15.4464 13.0089 13.0089 15.4464 10 15.4464Z" />
    <Path d="M10.0003 7.32416C9.28382 7.32416 8.61418 7.60095 8.10748 8.10988C7.60079 8.61657 7.32177 9.28622 7.32177 10.0027C7.32177 10.7193 7.60079 11.3889 8.10748 11.8956C8.61418 12.4001 9.28605 12.6813 10.0003 12.6813C10.7146 12.6813 11.3865 12.4023 11.8932 11.8956C12.3977 11.3889 12.6789 10.717 12.6789 10.0027C12.6789 9.28845 12.3999 8.61657 11.8932 8.10988C11.6458 7.85965 11.3509 7.66128 11.0259 7.52637C10.7009 7.39146 10.3522 7.32271 10.0003 7.32416Z" />
  </Icon>
)

export default ProjectIcon
