import React from 'react'
import { HStack, Stack, Text, VStack } from 'native-base'

import Icon, { IIconProps } from '../../Icon'
import CMSText, { ICMSTextProps } from '../CMSText'
import MarkdownText from '../MarkdownText'
export interface IDataPointProps extends ICMSTextProps {
  icon?: IIconProps
  mobileWeb?: boolean
  type?: string
  title?: string
  subtitle?: string
  onProcessLink?: () => null
  datapoints?: IDataPointProps[]
}

export const PlanDataPoint = ({
  icon,
  mobileWeb,
  onProcessLink,
  title,
  subtitle,
  type,
  datapoints,
  ...textProps
}: IDataPointProps) => {
  const { name: iconName, ...iconProps } = icon || {}

  return (type === "section") ? (
    <Stack space={4}>
      {datapoints ? (
        datapoints.map(datapoint => <PlanDataPoint
          {...datapoint}
          onProcessLink={onProcessLink}
          mobileWeb={mobileWeb}
          key={JSON.stringify(datapoint.values)}
        />)
      ) : null}
    </Stack>
  ) : (
    <HStack justifyContent="flex-start" space={4}>
      <Stack h="6" justifyContent="center">
        <Icon size={4} name="checkoutlined" color="secondary.500" isAntDesign />
      </Stack>
      <Stack maxWidth={"85%"}>
        <CMSText mobileWeb={mobileWeb} lineHeight={"1.2em"} {...textProps} />
        {subtitle ? (
          <MarkdownText
            fontSize="xs"
            lineHeight={"1.2em"}
            color="neutral.500"
            onProcessLink={onProcessLink}
          >{subtitle}</MarkdownText>
        ) : null}
      </Stack>
    </HStack>
  )
}

export default PlanDataPoint
