import React from 'react'
import { Icon, IIconProps, useTheme } from 'native-base'
import { Defs, G, LinearGradient, Path, Rect, Stop } from 'react-native-svg'

const WindIcon = ({ color, ...props }: IIconProps) => {
  const { colors } = useTheme() as any
  const fillColor = color || colors.secondary['500']

  return (
    <Icon width="144" height="144" viewBox="0 0 144 144" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.0499 35.17C81.7799 35.07 85.4399 37.02 87.9199 41.06C90.8499 45.82 89.0499 53.06 85.9199 56.06C82.4899 59.58 77.1199 59.6 69.9999 59.6H33.8199C30.9399 59.6 28.6499 59.78 27.9299 57.6C27.2199 56.73 27.6699 55.75 27.9299 54.76L29.4599 53.45H66.3199C71.4799 53.45 78.3899 54.21 81.3799 51.92C85.5499 48.73 82.5799 39.41 75.9199 41.23C70.7199 42.66 72.3399 46.95 69.3799 50.18C68.2676 50.2751 67.1479 50.1249 66.0999 49.74C63.4599 44.61 67.2699 38.89 70.8999 36.65C72.0999 36 73.8699 36 75.0499 35.17Z"
        fill="#FFF"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M107.55 37.57C116.1 37.44 121.83 41.34 124.35 47.16C127.35 54.05 124.69 63 120.21 66.36C113.46 71.42 105.33 70.29 93.8101 70.29H22.6901C20.9201 70.29 17.2001 70.86 15.9301 70.07C14.4001 69.62 13.9301 68.42 13.9301 66.36C14.5701 65.67 14.6501 64.97 15.6701 64.62C16.8901 63.71 20.4501 64.18 22.4301 64.18H110.57C114.47 63.28 117.47 61.18 118.86 57.85C121.63 51.14 116.56 45.23 111.66 43.85C105.86 42.26 101.17 46.55 99.6601 50.18C98.6601 52.54 99.4601 55.18 97.4801 56.5C96.0001 57.79 94.0001 56.47 93.3701 55.45C92.0501 53.72 93.4601 49.85 94.0301 48.45C95.9401 43.85 98.6601 40.53 103.19 38.64C104.48 38.12 106.35 38.3 107.55 37.57Z"
        fill="#FFF"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.29 53.49C21.6868 53.4479 23.083 53.5959 24.44 53.93C25.2428 54.9076 25.6337 56.1592 25.53 57.42C24.93 58.04 24.37 58.75 23.78 59.42C22.55 59.54 21 59.89 20.07 59.2C19.07 58.85 18.97 58.14 18.33 57.46V55.67C18.64 55.3 18.68 54.77 18.98 54.36C19.28 53.95 20 53.83 20.29 53.49Z"
        fill={fillColor}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43 74C45.57 74 48.39 74 50.64 74.22C51.12 74.83 51.64 74.89 51.94 75.74C52.1603 76.0541 52.2962 76.4195 52.3347 76.8013C52.3732 77.183 52.3131 77.5682 52.16 77.92C50.93 80.98 45.16 80.63 41.69 79.67L40.6 78.14C40.5765 77.2612 40.6502 76.3825 40.82 75.52C41.5751 75.0555 42.303 74.548 43 74Z"
        fill={fillColor}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58 74H92C104.72 74 113.44 72.93 116.87 82.5C120.03 91.32 111 101 101.82 98.21C99.0028 97.4274 96.5682 95.6431 94.9736 93.1922C93.379 90.7414 92.7341 87.7927 93.16 84.9C94.16 84.11 95.65 82.38 97.3 83.6C99.6 84.48 98.43 86.71 99.48 88.83C99.9331 89.6762 100.553 90.4218 101.302 91.0217C102.052 91.6215 102.915 92.0632 103.84 92.32C111.84 94.32 113.84 82.38 106.68 80.32C103.46 79.39 98.55 80.1 94.9 80.11H61.09C59.97 80.11 58.09 80.34 57.38 79.89C57.0156 79.7867 56.6838 79.5918 56.416 79.324C56.1482 79.0562 55.9533 78.7244 55.85 78.36C55 77.36 55.76 76.15 56.07 75.09C56.7423 74.7806 57.3879 74.416 58 74Z"
        fill="#FFF"
      />
      <Defs>
        <LinearGradient
          id="paint0_Linear_222_403"
          x1="27.5499"
          y1="47.38"
          x2="89.3999"
          y2="47.38"
          gradientUnits="userSpaceOnUse">
          <Stop stopOpacity="0" />
          <Stop offset="0.12" stopColor="#454545" stopOpacity="0.27" />
          <Stop offset="0.26" stopColor="#949494" stopOpacity="0.58" />
          <Stop offset="0.38" stopColor="#CECECE" stopOpacity="0.81" />
          <Stop offset="0.46" stopColor="#F1F1F1" stopOpacity="0.95" />
          <Stop offset="0.51" stopColor="white" />
        </LinearGradient>
        <LinearGradient
          id="paint1_Linear_222_403"
          x1="1573.2"
          y1="1816.34"
          x2="14045.2"
          y2="1816.34"
          gradientUnits="userSpaceOnUse">
          <Stop stopOpacity="0" />
          <Stop offset="0.12" stopColor="#454545" stopOpacity="0.27" />
          <Stop offset="0.26" stopColor="#949494" stopOpacity="0.58" />
          <Stop offset="0.38" stopColor="#CECECE" stopOpacity="0.81" />
          <Stop offset="0.46" stopColor="#F1F1F1" stopOpacity="0.95" />
          <Stop offset="0.51" stopColor="white" />
        </LinearGradient>
        <LinearGradient
          id="paint2_Linear_222_403"
          x1="3496.93"
          y1="2209.29"
          x2="7351.67"
          y2="2209.29"
          gradientUnits="userSpaceOnUse">
          <Stop stopOpacity="0" />
          <Stop offset="0.12" stopColor="#454545" stopOpacity="0.27" />
          <Stop offset="0.26" stopColor="#949494" stopOpacity="0.58" />
          <Stop offset="0.38" stopColor="#CECECE" stopOpacity="0.81" />
          <Stop offset="0.46" stopColor="#F1F1F1" stopOpacity="0.95" />
          <Stop offset="0.51" stopColor="white" />
        </LinearGradient>
      </Defs>
    </Icon>
  )
}

export default WindIcon
