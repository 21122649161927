import React from 'react'
import { Icon, IIconProps, useTheme } from 'native-base'
import { Path } from 'react-native-svg'

const IWSIcon = ({ color, ...props }: IIconProps) => {
  const { colors } = useTheme() as any
  const fillColor = color || colors.text['500']
  return (
    <Icon width="31" height="30" viewBox="0 0 31 30" {...props}>
      <Path
        d="M22.8875 8.02502H7.86877V13.0125H22.8875V8.02502Z"
        fill={fillColor}
      />
      <Path
        d="M22.9813 2.83124H7.71877C6.4157 2.8411 5.16908 3.3644 4.2494 4.28759C3.32972 5.21078 2.81117 6.45939 2.80627 7.76249V22.3125C2.81111 23.6173 3.32906 24.8679 4.24822 25.7941C5.16738 26.7203 6.41399 27.2477 7.71877 27.2625H22.9438C24.2566 27.2625 25.5156 26.741 26.444 25.8127C27.3723 24.8844 27.8938 23.6253 27.8938 22.3125V7.76249C27.8889 6.45939 27.3703 5.21078 26.4506 4.28759C25.531 3.3644 24.2843 2.8411 22.9813 2.83124ZM25.3063 22.3125C25.3063 22.9291 25.0613 23.5205 24.6253 23.9565C24.1893 24.3925 23.5979 24.6375 22.9813 24.6375H7.71877C7.1102 24.6229 6.53119 24.3721 6.10425 23.9382C5.67732 23.5042 5.43597 22.9212 5.43127 22.3125V7.76249C5.43609 7.15555 5.67806 6.57457 6.10548 6.14364C6.53291 5.71271 7.1119 5.46601 7.71877 5.45624H22.9438C23.2482 5.45376 23.5501 5.51159 23.8321 5.62638C24.1141 5.74117 24.3705 5.91066 24.5867 6.12506C24.8028 6.33946 24.9744 6.59454 25.0914 6.87557C25.2085 7.15661 25.2688 7.45804 25.2688 7.76249L25.3063 22.3125Z"
        fill={fillColor}
      />
      <Path
        d="M8.9938 15.75H7.62505C7.53185 15.75 7.4563 15.8256 7.4563 15.9188V21.9375C7.4563 22.0307 7.53185 22.1063 7.62505 22.1063H8.9938C9.087 22.1063 9.16255 22.0307 9.16255 21.9375V15.9188C9.16255 15.8256 9.087 15.75 8.9938 15.75Z"
        fill={fillColor}
      />
      <Path
        d="M17.8437 15.75H16.3625C16.3625 15.75 16.2125 15.75 16.175 15.9L15.5187 18.75C15.5187 18.75 15.5187 18.75 15.425 18.75L14.6937 15.8813C14.6847 15.8398 14.6604 15.8033 14.6256 15.779C14.5909 15.7547 14.5483 15.7443 14.5062 15.75H13.4375C13.3945 15.7523 13.3533 15.768 13.3197 15.7949C13.286 15.8218 13.2617 15.8586 13.25 15.9L12.5562 18.75L11.7312 15.8813C11.7254 15.8537 11.7134 15.8277 11.6962 15.8054C11.6789 15.7831 11.6569 15.765 11.6317 15.7523C11.6064 15.7397 11.5787 15.733 11.5505 15.7326C11.5223 15.7322 11.4943 15.7381 11.4687 15.75H10.0625C9.95 15.75 9.89375 15.75 9.93125 15.9375L11.6937 21.9563C11.7118 21.9957 11.7409 22.0291 11.7775 22.0524C11.8141 22.0756 11.8566 22.0879 11.9 22.0875H13.1937C13.2335 22.0875 13.2717 22.0717 13.2998 22.0436C13.3279 22.0155 13.3437 21.9773 13.3437 21.9375L13.9062 18.9375L14.5437 21.9375C14.5437 22.05 14.5437 22.0875 14.7312 22.0875H16.1187C16.1601 22.0894 16.2009 22.0776 16.2348 22.0538C16.2688 22.0301 16.2939 21.9958 16.3062 21.9563L17.9937 15.9375C17.9996 15.915 18.0001 15.8914 17.9952 15.8686C17.9903 15.8458 17.9802 15.8244 17.9657 15.8062C17.9511 15.788 17.9325 15.7735 17.9113 15.7637C17.8902 15.754 17.867 15.7493 17.8437 15.75Z"
        fill={fillColor}
      />
      <Path
        d="M21.3125 18.3375C20.375 18.0375 20.0375 17.9063 20.0375 17.5688C20.0375 17.2313 20.2625 17.1375 20.6562 17.1375C20.8724 17.111 21.0903 17.1709 21.2624 17.3044C21.4346 17.4379 21.5469 17.634 21.575 17.85C21.575 17.85 21.575 17.85 21.7437 17.85H23.075C23.0979 17.8531 23.1212 17.8509 23.1431 17.8435C23.1649 17.8361 23.1848 17.8238 23.2012 17.8074C23.2175 17.7911 23.2298 17.7712 23.2372 17.7493C23.2446 17.7275 23.2468 17.7042 23.2437 17.6813C23.2255 17.3752 23.1448 17.076 23.0067 16.8022C22.8686 16.5284 22.676 16.2857 22.4407 16.089C22.2054 15.8924 21.9324 15.7459 21.6384 15.6586C21.3445 15.5713 21.0357 15.5449 20.7312 15.5813C19.3812 15.5813 18.35 16.3313 18.35 17.4563C18.35 18.5813 19.625 19.3313 20.4687 19.5C21.3125 19.6688 21.575 19.7813 21.575 20.1188C21.575 20.4563 21.4625 20.5688 20.9562 20.5688C20.45 20.5688 20.0187 20.2313 20.0187 19.9313C20.0187 19.9313 20.0187 19.8375 19.85 19.8375H18.5187C18.4958 19.8344 18.4726 19.8367 18.4507 19.844C18.4288 19.8514 18.4089 19.8638 18.3926 19.8801C18.3762 19.8964 18.3639 19.9163 18.3565 19.9382C18.3491 19.9601 18.3469 19.9834 18.35 20.0063V20.1C18.35 21.3375 19.3437 22.125 20.825 22.125C22.3062 22.125 23.2812 21.3563 23.2812 20.25C23.2812 19.1438 21.9312 18.5438 21.3125 18.3375Z"
        fill={fillColor}
      />
    </Icon>
  )
}

export default IWSIcon