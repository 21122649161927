import React from 'react'
import { Icon, IIconProps, useTheme } from 'native-base'
import { Path } from 'react-native-svg'

const HailStormHistoryIcon = ({ color, ...props }: IIconProps) => {
  const { colors } = useTheme() as any
  const fillColor = color || colors.secondary['500']

  return (
    <Icon {...props} width="36" height="36" viewBox="0 0 36 36" >
      <Path d="M21.1274 25.2899C20.6868 25.253 20.2451 25.3466 19.8575 25.5592C19.4698 25.7718 19.1534 26.094 18.9477 26.4853C18.7421 26.8767 18.6562 27.32 18.701 27.7598C18.7458 28.1997 18.9191 28.6166 19.1994 28.9585C19.4797 29.3004 19.8546 29.5522 20.2771 29.6823C20.6996 29.8125 21.1512 29.8153 21.5753 29.6903C21.9994 29.5654 22.3773 29.3183 22.6618 28.9798C22.9462 28.6414 23.1247 28.2267 23.1749 27.7874C23.2091 27.4882 23.1829 27.1851 23.0977 26.8962C23.0126 26.6073 22.8704 26.3384 22.6795 26.1055C22.4885 25.8726 22.2528 25.6804 21.9861 25.5403C21.7195 25.4002 21.4275 25.315 21.1274 25.2899Z" fill={fillColor} />
      <Path d="M21.0824 23.3999L23.8949 24.8174L27.2924 18.1349L24.4799 16.7174L21.0824 23.3999Z" fill={fillColor} />
      <Path d="M24.8849 10.1924C23.8763 10.1983 22.8773 10.3889 21.9374 10.7549C20.7496 8.86101 18.9781 7.40477 16.8902 6.60584C14.8023 5.8069 12.5112 5.7086 10.3625 6.32578C8.21382 6.94295 6.32412 8.24211 4.97845 10.0273C3.63278 11.8125 2.90412 13.9868 2.90241 16.2224C2.89597 18.0427 3.36872 19.8327 4.27315 21.4125C5.17758 22.9923 6.48185 24.3063 8.05491 25.2224L9.22491 22.1399C8.30158 21.5184 7.53651 20.6894 6.99092 19.7193C6.44532 18.7492 6.13433 17.6648 6.08282 16.553C6.03132 15.4412 6.24073 14.3327 6.69431 13.3163C7.14788 12.2999 7.83304 11.4038 8.69495 10.6996C9.55687 9.99536 10.5716 9.50264 11.6581 9.26083C12.7445 9.01901 13.8724 9.03481 14.9517 9.30696C16.0309 9.57911 17.0315 10.1001 17.8733 10.8281C18.7152 11.5562 19.375 12.4712 19.7999 13.4999L22.0499 14.1974C22.873 13.6592 23.834 13.3702 24.8174 13.3649C25.8963 13.3412 26.9538 13.6676 27.8317 14.2951C28.7096 14.9227 29.3607 15.8177 29.6875 16.8461C30.0142 17.8746 29.9992 18.9812 29.6445 20.0004C29.2898 21.0196 28.6146 21.8965 27.7199 22.4999L29.4974 25.0874C30.9288 24.1107 32.0114 22.7029 32.5878 21.0688C33.1643 19.4346 33.2045 17.6592 32.7026 16.0005C32.2008 14.3419 31.183 12.8866 29.7973 11.8461C28.4116 10.8056 26.7302 10.2342 24.9974 10.2149L24.8849 10.1924Z" fill={fillColor} />
      <Path d="M15.3449 21.0374L17.2574 15.9974H13.8824L10.7774 24.1874H15.0299L13.2074 28.8674H15.2099L19.6424 21.0374H15.3449Z" fill={fillColor} />
    </Icon>
  )
}

export default HailStormHistoryIcon
