import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

const PlusIcon = (props: IIconProps) => (
  <Icon width="31" height="34" viewBox="0 0 31 34" {...props}>
    <Path d="M16.8389 0.930664H14.1604C13.9223 0.930664 13.8032 1.04971 13.8032 1.28781V15.3057H0.500698C0.262602 15.3057 0.143555 15.4247 0.143555 15.6628V18.3414C0.143555 18.5795 0.262602 18.6985 0.500698 18.6985H13.8032V32.7164C13.8032 32.9545 13.9223 33.0735 14.1604 33.0735H16.8389C17.077 33.0735 17.1961 32.9545 17.1961 32.7164V18.6985H30.5007C30.7388 18.6985 30.8578 18.5795 30.8578 18.3414V15.6628C30.8578 15.4247 30.7388 15.3057 30.5007 15.3057H17.1961V1.28781C17.1961 1.04971 17.077 0.930664 16.8389 0.930664Z" />
  </Icon>
)

export default PlusIcon
