import React from 'react'
import { Icon, IIconProps, useTheme } from 'native-base'
import { Path } from 'react-native-svg'

const SolutionIcon = ({ color, ...props }: IIconProps) => {
  const { colors } = useTheme() as any
  const fillColor = color || colors.text['50']
  return (
    <Icon width="36" height="36" viewBox="0 0 36 36"  {...props}>
      <Path
        d="M25.0724 8.02818C25.0724 7.8514 24.9277 7.70675 24.7509 7.70675H9.32237C9.14559 7.70675 9.00094 7.8514 9.00094 8.02818V9.95675C9.00094 10.1335 9.14559 10.2782 9.32237 10.2782H24.7509C24.9277 10.2782 25.0724 10.1335 25.0724 9.95675V8.02818ZM24.7509 13.4925H9.32237C9.14559 13.4925 9.00094 13.6371 9.00094 13.8139V15.7425C9.00094 15.9193 9.14559 16.0639 9.32237 16.0639H24.7509C24.9277 16.0639 25.0724 15.9193 25.0724 15.7425V13.8139C25.0724 13.6371 24.9277 13.4925 24.7509 13.4925ZM16.7152 19.2782H9.32237C9.14559 19.2782 9.00094 19.4228 9.00094 19.5996V21.5282C9.00094 21.705 9.14559 21.8496 9.32237 21.8496H16.7152C16.892 21.8496 17.0367 21.705 17.0367 21.5282V19.5996C17.0367 19.4228 16.892 19.2782 16.7152 19.2782ZM14.7867 31.6532H5.78666V3.36747H28.2867V17.1889C28.2867 17.3657 28.4313 17.5103 28.6081 17.5103H30.8581C31.0349 17.5103 31.1795 17.3657 31.1795 17.1889V1.76032C31.1795 1.04916 30.605 0.474609 29.8938 0.474609H4.17951C3.46835 0.474609 2.8938 1.04916 2.8938 1.76032V33.2603C2.8938 33.9715 3.46835 34.546 4.17951 34.546H14.7867C14.9634 34.546 15.1081 34.4014 15.1081 34.2246V31.9746C15.1081 31.7978 14.9634 31.6532 14.7867 31.6532ZM29.1224 28.6639C30.2875 27.6072 31.0188 26.0804 31.0188 24.3809C31.0188 21.1867 28.4273 18.5951 25.2331 18.5951C22.0389 18.5951 19.4474 21.1867 19.4474 24.3809C19.4474 26.0804 20.1786 27.6072 21.3438 28.6639C19.0536 29.9697 17.4786 32.3885 17.3581 35.1768C17.35 35.3576 17.4987 35.5103 17.6795 35.5103H19.6121C19.7809 35.5103 19.9215 35.3777 19.9335 35.205C20.0902 32.4246 22.4085 30.2068 25.2331 30.2068C28.0576 30.2068 30.3759 32.4246 30.5326 35.205C30.5407 35.3737 30.6813 35.5103 30.8541 35.5103H32.7867C32.9715 35.5103 33.1161 35.3576 33.1081 35.1768C32.9916 32.3844 31.4125 29.9697 29.1224 28.6639ZM25.2331 21.1666C27.009 21.1666 28.4474 22.605 28.4474 24.3809C28.4474 26.1568 27.009 27.5951 25.2331 27.5951C23.4572 27.5951 22.0188 26.1568 22.0188 24.3809C22.0188 22.605 23.4572 21.1666 25.2331 21.1666Z"
        fill={fillColor}
      />
    </Icon>
  )
}
export default SolutionIcon
