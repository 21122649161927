import React from 'react'
import Svg, { Defs, Path, LinearGradient, Stop } from 'react-native-svg'

export const EagleViewShortIcon = () => (
  <Svg width="20" height="20" viewBox="0 0 20 20">
    <Path fill-rule="evenodd" clip-rule="evenodd" d="M7.10333 2.8125H9.88832C9.92448 2.8125 9.95914 2.82704 9.98461 2.85289C10.0101 2.87874 10.0243 2.91376 10.024 2.95018V5.48832C10.024 5.5638 9.96325 5.625 9.88832 5.625H7.10333" fill="#8CCC4C" />
    <Path fill-rule="evenodd" clip-rule="evenodd" d="M1.06892 2.8125L7.7524 2.8125V5.625H1.06892C0.996341 5.625 0.9375 5.5638 0.9375 5.48832V2.94519C0.939576 2.87127 0.997813 2.81247 1.06892 2.8125Z" fill="#0098C5" />
    <Path fill-rule="evenodd" clip-rule="evenodd" d="M7.10333 14.375H9.88832C9.92448 14.375 9.95914 14.3895 9.98461 14.4154C10.0101 14.4412 10.0243 14.4763 10.024 14.5127V17.0508C10.024 17.1263 9.96325 17.1875 9.88832 17.1875H7.10333" fill="#8CCC4C" />
    <Path fill-rule="evenodd" clip-rule="evenodd" d="M1.06892 14.375H7.7524V17.1875H1.06892C0.996341 17.1875 0.9375 17.1264 0.9375 17.051V14.5115C0.938023 14.4363 0.996558 14.3755 1.06892 14.375Z" fill="#0098C5" />
    <Path fill-rule="evenodd" clip-rule="evenodd" d="M2.88458 8.4375L9.88843 8.44943C9.96324 8.4505 10.0235 8.51099 10.024 8.58558V11.1148C10.0237 11.1511 10.009 11.1858 9.98295 11.2112C9.95693 11.2365 9.92184 11.2505 9.88544 11.25L2.88458 11.2391" fill="#8CCC4C" />
    <Path fill-rule="evenodd" clip-rule="evenodd" d="M1.06559 8.4375H4.18269V11.25H1.06559C0.995059 11.2495 0.938009 11.1886 0.9375 11.1133V8.57418C0.9375 8.4987 0.994847 8.4375 1.06559 8.4375Z" fill="#0098C5" />
    <Path fill-rule="evenodd" clip-rule="evenodd" d="M18.9775 2.8125H15.9601C15.9125 2.81316 15.8689 2.83875 15.8458 2.87958L10.366 17.0236C10.3152 17.1118 10.3816 17.1884 10.4842 17.1875H13.4146C13.4636 17.183 13.507 17.1545 13.5299 17.1118L19.0956 3.00992C19.117 2.9686 19.1154 2.91948 19.0915 2.87955C19.0676 2.83962 19.0246 2.81436 18.9775 2.8125Z" fill="url(#paint0_linear_14449_139957)" />
    <Defs>
      <LinearGradient id="paint0_linear_14449_139957" x1="448.721" y1="2.42917" x2="448.721" y2="1439.93" gradientUnits="userSpaceOnUse">
        <Stop stop-color="#0098C5" />
        <Stop offset="0.17" stop-color="#0098C5" />
        <Stop offset="0.54" stop-color="#0098C5" stop-opacity="0.43" />
        <Stop offset="0.84" stop-color="#0098C5" stop-opacity="0" />
        <Stop offset="1" stop-color="#0098C5" stop-opacity="0" />
      </LinearGradient>
    </Defs>
  </Svg>
)

export default EagleViewShortIcon