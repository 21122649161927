import React from 'react'
import { Icon, IIconProps } from 'native-base'
import { G, Path, Rect } from 'react-native-svg'

const StarterPlanIcon = (props: IIconProps) => (
  <Icon {...props} width="76.272" height="44.061" viewBox="0 0 76.272 44.061">
    <G
      id="Illustr_Single_House"
      data-name="Illustr/Single House"
      transform="translate(0 0)">
      <Rect
        id="Rectangle_39"
        data-name="Rectangle 39"
        width="36.994"
        height="26.737"
        transform="translate(31.209 15.858)"
        fill="#3c6799"
      />
      <Path
        id="Path_50"
        data-name="Path 50"
        d="M17.134,0,5.645,15.858V42.595h23.14V15.858Z"
        transform="translate(2.424)"
        fill="#1d3c5f"
      />
      <Path
        id="Path_51"
        data-name="Path 51"
        d="M13.683,0,25.334,15.858l37-.019L50.691,0Z"
        transform="translate(5.875)"
        fill="#469bff"
      />
      <Rect
        id="Rectangle_40"
        data-name="Rectangle 40"
        width="76.272"
        height="1.467"
        transform="translate(0 42.595)"
        fill="#1d3c5f"
      />
    </G>
  </Icon>
)

export default StarterPlanIcon
