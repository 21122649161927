import React from 'react'
import { Icon, IIconProps, useTheme } from 'native-base'
import { Path } from 'react-native-svg'

const ExternalLinkIcon = ({ color, ...props }: IIconProps) => {
  const { colors } = useTheme()
  const fillColor = color || colors.primary['500']

  return (
      <Icon width="36" height="36" viewBox="0 0 36 36" {...props}>
        <Path
          fill={fillColor}
          d="M1.934 3.214v29.571c0 .711.574 1.286 1.285 1.286h29.572c.71 0 1.286-.575 1.286-1.286V18.321a.322.322 0 0 0-.322-.322h-2.25a.322.322 0 0 0-.321.322v12.857H4.826V4.821h12.858a.322.322 0 0 0 .321-.322V2.25a.322.322 0 0 0-.321-.321H3.219c-.71 0-1.285.575-1.285 1.286Z"
        />
        <Path
          fill={fillColor}
          d="m30.549 7.66 2.097 2.097a.322.322 0 0 0 .547-.19l.843-7.207a.322.322 0 0 0-.357-.358l-7.208.844a.322.322 0 0 0-.19.546l2.106 2.106L18.093 15.79a.323.323 0 0 0 0 .454l1.704 1.704c.125.125.33.125.454 0L30.549 7.66Z"
        />
    </Icon>
  )
}

export default ExternalLinkIcon
