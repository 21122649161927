import React from 'react'
import { Icon, IIconProps, useTheme } from 'native-base'
import { Path } from 'react-native-svg'

const EnergyCons = ({ color, ...props }: IIconProps) => {
  const { colors } = useTheme() as any
  const white = colors.text['50']
  return (
    <Icon {...props} width="36" height="36" viewBox="0 0 36 36">
      <Path
        d="M28.305 7.69501C27.3825 6.75001 16.0875 7.89751 11.25 12.8475C6.99748 17.1225 6.74998 21.4425 8.77498 24.6825L15.525 17.9325L18 20.655L11.25 27.405C14.49 29.52 18.81 29.205 23.085 24.93C28.035 19.9575 29.1825 8.68501 28.2375 7.76251"
        fill={color || white}
      />
    </Icon>
  )
}

export default EnergyCons
