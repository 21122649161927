import React from 'react'
import { Icon, IIconProps, useTheme } from 'native-base'
import { Path } from 'react-native-svg'

const WeatherLockIcon = ({ color, ...props }: IIconProps) => {
  const { colors } = useTheme() as any
  const fillColor = color || colors.text['50']
  return (
    <Icon width="20" height="20" viewBox="0 0 20 20"  {...props}>
      <Path
        d="M10 1.875C7.59766 1.875 5.625 3.84766 5.625 6.25V8.125H4.75C4.19772 8.125 3.75 8.57272 3.75 9.125V17.125C3.75 17.6773 4.19772 18.125 4.75 18.125H15.25C15.8023 18.125 16.25 17.6773 16.25 17.125V9.125C16.25 8.57272 15.8023 8.125 15.25 8.125H14.375V6.25C14.375 3.84766 12.4023 1.875 10 1.875ZM10 3.125C11.7212 3.125 13.125 4.52881 13.125 6.25V8.125H6.875V6.25C6.875 4.52881 8.27881 3.125 10 3.125ZM5 9.375H15V16.875H5V9.375Z"
        fill={fillColor}
      />
      <Path
        d="M8.26448 16.0004C8.20581 16.0004 8.16848 15.9737 8.15248 15.9204L6.69648 10.5044L6.68848 10.4724C6.68848 10.4244 6.71781 10.4004 6.77648 10.4004H7.56048C7.61914 10.4004 7.65648 10.4271 7.67248 10.4804L8.58448 14.1284C8.58981 14.1444 8.59781 14.1524 8.60848 14.1524C8.61914 14.1524 8.62714 14.1444 8.63248 14.1284L9.50448 10.4804C9.52048 10.4271 9.55514 10.4004 9.60848 10.4004H10.3765C10.4351 10.4004 10.4725 10.4271 10.4885 10.4804L11.4165 14.1364C11.4218 14.1524 11.4298 14.1604 11.4405 14.1604C11.4511 14.1604 11.4591 14.1524 11.4645 14.1364L12.3605 10.4804C12.3765 10.4271 12.4138 10.4004 12.4725 10.4004H13.2165C13.2911 10.4004 13.3205 10.4351 13.3045 10.5044L11.9205 15.9204C11.9045 15.9737 11.8671 16.0004 11.8085 16.0004H11.0645C11.0058 16.0004 10.9685 15.9737 10.9525 15.9204L10.0165 12.1044C10.0111 12.0884 10.0031 12.0804 9.99248 12.0804C9.98181 12.0751 9.97381 12.0831 9.96848 12.1044L9.07248 15.9204C9.05648 15.9737 9.02181 16.0004 8.96848 16.0004H8.26448Z"
        fill={fillColor}
      />
    </Icon>
  )
}

export default WeatherLockIcon
