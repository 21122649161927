import React from 'react'
import { Icon, IIconProps, useTheme } from 'native-base'
import { Path } from 'react-native-svg'

const FeatureDroneIcon = ({ color, ...props }: IIconProps) => {
  const { colors } = useTheme() as any
  const fillColor = color || colors.primary['500']
  return (
    <Icon {...props} width="24" height="24" viewBox="0 0 24 24">
      <Path
        d="M18.1928 19.5C17.9527 19.301 17.7275 19.0848 17.5189 18.8531C16.0147 17.4293 14.0601 16.5759 11.9933 16.4407C9.93431 16.5753 7.98807 17.4292 6.49467 18.8531C6.29103 19.0804 6.07507 19.2964 5.84777 19.5L4.50006 18.1523L5.12001 17.5323C6.57276 16.0365 7.43043 14.0625 7.53241 11.9798C7.42351 9.97258 6.61806 8.06618 5.25478 6.58895C5.01618 6.37397 4.79113 6.14442 4.58092 5.90162L5.92863 4.55391C5.92863 4.55391 6.23861 4.82345 6.62944 5.24124C8.08485 6.62914 9.98566 7.45537 11.9933 7.57278C14.0072 7.44794 15.914 6.62321 17.3842 5.24124C17.6176 4.97634 17.8698 4.72862 18.1389 4.5L19.4866 5.84771C19.4866 5.84771 19.1901 6.18464 18.7588 6.57547C17.3454 8.02985 16.4992 9.94214 16.3734 11.9663C16.4832 14.0367 17.3516 15.9941 18.8127 17.465C19.2036 17.8154 19.4327 18.0714 19.4731 18.1119L18.1928 19.5ZM8.82621 8.83962C9.21444 9.84122 9.41544 10.9056 9.4192 11.9798C9.41346 13.0669 9.21261 14.1442 8.82621 15.1604C10.8456 14.3248 13.1141 14.3248 15.1335 15.1604C14.7311 14.1479 14.5209 13.0693 14.5135 11.9798C14.5172 10.8987 14.7276 9.82821 15.1335 8.82615C14.1351 9.2303 13.0703 9.4451 11.9933 9.45957C10.9077 9.45697 9.83267 9.24653 8.82621 8.83962V8.83962Z"
        fill={fillColor}
      />
    </Icon>
  )
}

export default FeatureDroneIcon
