import React from 'react'
import { Icon, IIconProps } from 'native-base'
import { Defs, LinearGradient, Path, Stop } from 'react-native-svg'

const EnterprisePlanTwoIcon = (props: IIconProps) => (
  <Icon {...props} id="Layer_1-042586" viewBox="0 0 240 120">
    <Defs>
      <LinearGradient
        id="linear-gradient-042586"
        x1="5.13"
        y1="118.235"
        x2="5.13"
        y2="117.732"
        gradientTransform="matrix(53.618 0 0 -82.061 -154.502 9754.529)"
        gradientUnits="userSpaceOnUse">
        <Stop offset="0" stopColor="#4b438d" />
        <Stop offset="1" stopColor="#27234a" />
      </LinearGradient>
      <LinearGradient
        id="linear-gradient-2-042586"
        x1="-10.97"
        y1="127.37"
        x2="-10.467"
        y2="127.37"
        gradientTransform="matrix(-21.308 0 0 27.191 -174.229 -3370.729)"
        gradientUnits="userSpaceOnUse">
        <Stop offset="0" stopColor="#46509b" />
        <Stop offset="1" stopColor="#27234a" />
      </LinearGradient>
      <LinearGradient
        id="linear-gradient-3-042586"
        x1="7.355"
        y1="117.57"
        x2="7.355"
        y2="117.067"
        gradientTransform="matrix(39.841 0 0 -61.424 -211.51 7290.117)"
      // xlink:href="#linear-gradient"
      >
        <Stop offset="0" stopColor="#46509b" />
        <Stop offset="1" stopColor="#27234a" />
      </LinearGradient>
      <LinearGradient
        id="linear-gradient-4-042586"
        x1="3.726"
        y1="116.444"
        x2="3.726"
        y2="115.941"
        gradientTransform="matrix(61.395 0 0 -44.382 -49.552 5245.072)">
        <Stop offset="0" stopColor="#46509b" />
        <Stop offset="1" stopColor="#27234a" />
      </LinearGradient>
      <LinearGradient
        id="linear-gradient-5-042586"
        x1="5.271"
        y1="115.653"
        x2="5.271"
        y2="115.15"
        gradientTransform="matrix(47 0 0 -37.141 -109.568 4376.158)">
        <Stop offset="0" stopColor="#46509b" />
        <Stop offset="1" stopColor="#27234a" />
      </LinearGradient>
    </Defs>
    <Path
      id="Path_1-042586"
      d="m55.614 68.865 20.518-19.237 13.276 5.648 24.646-17.862 33.472 21.95 18.18-11.976 18.604 23.055 15.564-3.324 24.677 32.239H14.993l16.704-26.675 12.908 7.86 11.01-11.678Z"
      fill="#dbdbdb"
      fillOpacity="0.1"
    />
    <Path
      id="Rectangle_1-042586"
      fill="url(#linear-gradient-042586)"
      d="M107.108 52.054h26.959v41.26h-26.959z"
    />
    <Path
      id="Path_2-042586"
      fill="#000000"
      d="M107.109 52.05v41.266H93.307V52.05l6.901-9.327 6.9 9.327Z"
    />
    <Path
      id="Path_3-042586"
      fill="#8681b3"
      d="M100.208 42.723h27.09l6.772 9.33-26.96-.002-6.902-9.328Z"
    />
    <Path
      id="Rectangle_2-042586"
      fill="url(#linear-gradient-2-042586)"
      d="M48.803 85.763h10.714v13.672H48.803z"
    />
    <Path
      id="Path_4-042586"
      fill="#000000"
      d="M36.328 85.763v13.67h12.477v-13.67l-6.241-8.443-6.236 8.443Z"
    />
    <Path
      id="Path_5-042586"
      fill="#7c81e0"
      d="m59.517 85.763-6.423-8.443h-10.53l6.238 8.443h10.715Z"
    />
    <Path
      id="Rectangle_3-042586"
      fill="#000000"
      d="M130.104 60.48h7.583v17.574h-7.583z"
    />
    <Path
      id="Path_6-042586"
      fill="#9588e2"
      d="M153.725 60.48v17.572h-16.037V60.48l8.023-10.854 8.014 10.854Z"
    />
    <Path
      id="Path_7-042586"
      fill="#6b63b7"
      d="m130.104 60.48 8.257-10.853h7.35l-8.025 10.854-7.582-.001Z"
    />
    <Path
      id="Rectangle_4-042586"
      fill="url(#linear-gradient-3-042586)"
      d="M71.518 68.478H91.55v30.884H71.518z"
    />
    <Path
      id="Path_8-042586"
      fill="#000000"
      d="M71.518 68.472v30.893H51.99V68.471l9.763-13.197 9.764 13.197Z"
    />
    <Path
      id="Path_9-042586"
      fill="#8681b3"
      d="M61.754 55.276H82.07l9.577 13.202-20.127-.006-9.766-13.196Z"
    />
    <Path
      id="Rectangle_5-042586"
      fill="url(#linear-gradient-4-042586)"
      d="M163.787 77.044h30.869v22.315h-30.869z"
    />
    <Path
      id="Path_10-042586"
      fill="#000000"
      d="M163.787 77.039v22.324H144.26V77.039l9.764-13.198 9.763 13.198Z"
    />
    <Path
      id="Path_11-042586"
      fill="#8681b3"
      d="M154.024 63.842h31.056l9.574 13.201-30.869-.004-9.761-13.197Z"
    />
    <Path
      id="Path_12-042586"
      d="M150.005 80.688v18.675h-23.631V80.688"
      fill="url(#linear-gradient-5-042586)"
    />
    <Path
      id="Path_13-042586"
      fill="#000000"
      d="M126.374 82.275v17.09h-14.95v-17.09l7.474-10.103 7.475 10.103Z"
    />
    <Path
      id="Path_14-042586"
      fill="#7c81e0"
      d="M118.897 72.172h23.775l7.331 10.106-23.631-.003-7.475-10.103Z"
    />
    <Path
      id="Rectangle_6-042586"
      fill="#000000"
      d="M86.647 76.076h6.663v23.358h-6.663z"
    />
    <Path
      id="Path_15-042586"
      fill="#9588e2"
      d="M114.625 76.078V99.43H93.306V76.077l10.663-14.425 10.656 14.425Z"
    />
    <Path
      id="Path_16-042586"
      fill="#6b63b7"
      d="m86.647 76.076 10.974-14.424h6.35L93.306 76.077h-6.66Z"
    />
    <Path d="M7.021 98.869h225.502V100H7.021z" />
    <Path
      id="Path_17-042586"
      fill="#fcb627"
      d="M46.766 55.528a3.141 3.141 0 0 0-3.142 3.139c0 1.734 3.142 6.766 3.142 6.766s3.14-5.032 3.14-6.766a3.138 3.138 0 0 0-3.138-3.14h-.002Zm0 4.457a1.318 1.318 0 1 1 1.315-1.321v.003c.001.727-.588 1.317-1.315 1.318Z"
    />
    <Path
      id="Path_18-042586"
      fill="#fcb627"
      d="M96.964 20.99a6.36 6.36 0 0 0-6.363 6.357c0 3.511 6.363 13.702 6.363 13.702s6.356-10.19 6.356-13.702a6.354 6.354 0 0 0-6.351-6.357h-.004Zm0 9.026a2.669 2.669 0 1 1 2.665-2.673v.004a2.668 2.668 0 0 1-2.665 2.667v.002Z"
    />
    <Path
      id="Path_19-042586"
      fill="#fcb627"
      d="M180.4 45.203a4.345 4.345 0 0 0-4.346 4.343c0 2.4 4.347 9.361 4.347 9.361s4.342-6.961 4.342-9.36a4.341 4.341 0 0 0-4.338-4.344h-.004Zm0 6.165a1.824 1.824 0 1 1 1.82-1.828v.007c-.001 1.005-.815 1.819-1.82 1.82Z"
    />
    <Path
      id="Path_83-042586"
      d="m149.922 79.425 3.007 4.354-.99 1.366-5.61-8.076 3.593 2.356Z"
      fill="#000000"
    />
  </Icon>
)

export default EnterprisePlanTwoIcon
