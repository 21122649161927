import React from 'react'
import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

const VentilationCalculatorIcon = (props: IIconProps) => (
  <Icon {...props} viewBox="0 0 25 26">
    <Path
      d="M21.0489 5.81466H5.68047V10.9183H21.0489V5.81466Z"
      fill="#207EED"
    />
    <Path
      d="M21.1448 0.5H5.56536C4.22529 0.49999 2.93983 1.03101 1.99046 1.97678C1.04109 2.92255 0.505198 4.20599 0.500122 5.54605V20.4348C0.500122 21.7781 1.03378 23.0665 1.98369 24.0164C2.93361 24.9663 4.22197 25.5 5.56536 25.5H21.1448C22.4882 25.5 23.7765 24.9663 24.7264 24.0164C25.6764 23.0665 26.21 21.7781 26.21 20.4348V5.54605C26.2049 4.20599 25.669 2.92255 24.7197 1.97678C23.7703 1.03101 22.4849 0.49999 21.1448 0.5ZM23.5239 20.4348C23.5189 21.0642 23.2666 21.6664 22.8215 22.1115C22.3764 22.5566 21.7742 22.8089 21.1448 22.8139H5.56536C4.93592 22.8089 4.33369 22.5566 3.8886 22.1115C3.44351 21.6664 3.19125 21.0642 3.18623 20.4348V5.54605C3.19129 4.91839 3.44418 4.31817 3.88981 3.87614C4.33543 3.43411 4.93768 3.18609 5.56536 3.18611H21.1448C21.7725 3.18609 22.3747 3.43411 22.8203 3.87614C23.266 4.31817 23.5188 4.91839 23.5239 5.54605V20.4348Z"
      fill="#207EED"
    />
    <Path
      d="M6.2177 19.3603L4.87464 14.8707C4.87464 14.8707 4.87464 14.7364 4.98976 14.7364H6.10258C6.1382 14.7364 6.17236 14.7505 6.19755 14.7757C6.22273 14.8009 6.23688 14.8351 6.23688 14.8707L6.90841 17.6719L7.61831 14.8707C7.62296 14.8385 7.63913 14.8091 7.66383 14.7879C7.68854 14.7667 7.72008 14.7552 7.75262 14.7556H8.84625C8.84625 14.7556 8.98055 14.7556 8.96136 14.8899L7.61831 19.4179C7.60961 19.4517 7.58967 19.4815 7.56177 19.5024C7.53387 19.5233 7.49968 19.5341 7.46482 19.533H6.37119C6.34931 19.5332 6.32765 19.5287 6.30765 19.5198C6.28766 19.5109 6.2698 19.4978 6.25526 19.4815C6.24073 19.4651 6.22985 19.4459 6.22338 19.425C6.2169 19.4041 6.21496 19.382 6.2177 19.3603Z"
      fill="#207EED"
    />
    <Path
      d="M12.7219 15.7916H10.8033V16.3864H11.9353C11.9529 16.3864 11.9704 16.3899 11.9867 16.3966C12.003 16.4034 12.0178 16.4133 12.0302 16.4257C12.0427 16.4382 12.0526 16.453 12.0594 16.4693C12.0661 16.4856 12.0696 16.5031 12.0696 16.5207V17.3457C12.073 17.3641 12.0719 17.3831 12.0664 17.4009C12.0608 17.4188 12.051 17.4351 12.0378 17.4483C12.0246 17.4615 12.0083 17.4713 11.9905 17.4769C11.9726 17.4824 11.9537 17.4835 11.9353 17.48H10.7265V18.1516H12.6452C12.661 18.1479 12.6776 18.1483 12.6932 18.1528C12.7089 18.1573 12.7232 18.1657 12.7347 18.1772C12.7462 18.1887 12.7546 18.203 12.7591 18.2186C12.7635 18.2343 12.764 18.2508 12.7603 18.2667V19.1109C12.7603 19.1109 12.7603 19.2452 12.6452 19.2452H9.51777C9.49938 19.2486 9.48044 19.2475 9.46257 19.242C9.4447 19.2365 9.42845 19.2267 9.41523 19.2134C9.402 19.2002 9.39219 19.184 9.38666 19.1661C9.38113 19.1482 9.38003 19.1293 9.38347 19.1109V14.8707C9.38003 14.8523 9.38113 14.8333 9.38666 14.8155C9.39219 14.7976 9.402 14.7814 9.41523 14.7681C9.42845 14.7549 9.4447 14.7451 9.46257 14.7396C9.48044 14.734 9.49938 14.7329 9.51777 14.7364H12.7219C12.7219 14.7364 12.837 14.7364 12.837 14.8707V15.7149C12.8348 15.7276 12.8299 15.7397 12.8228 15.7505C12.8158 15.7613 12.8066 15.7706 12.7958 15.7778C12.7851 15.7849 12.773 15.7899 12.7603 15.7922C12.7476 15.7946 12.7345 15.7944 12.7219 15.7916Z"
      fill="#207EED"
    />
    <Path
      d="M13.4126 19.3411V14.8707C13.4126 14.8707 13.4126 14.7364 13.5277 14.7364H14.5254C14.563 14.7361 14.5994 14.7496 14.6277 14.7743C14.656 14.7991 14.6742 14.8334 14.6789 14.8707L16.022 17.1155V14.8707C16.0186 14.8523 16.0197 14.8333 16.0252 14.8155C16.0307 14.7976 16.0405 14.7814 16.0538 14.7681C16.067 14.7549 16.0832 14.7451 16.1011 14.7396C16.119 14.734 16.1379 14.7329 16.1563 14.7364H17.1732C17.2088 14.7364 17.243 14.7505 17.2682 14.7757C17.2933 14.8009 17.3075 14.8351 17.3075 14.8707V19.3795C17.3075 19.4151 17.2933 19.4493 17.2682 19.4745C17.243 19.4997 17.2088 19.5138 17.1732 19.5138H16.2522C16.2203 19.5136 16.189 19.5045 16.1619 19.4876C16.1348 19.4706 16.1129 19.4465 16.0987 19.4179L14.7365 17.1155V19.3603C14.7365 19.4563 14.7365 19.4946 14.6214 19.4946H13.5277C13.5277 19.4946 13.4126 19.4179 13.4126 19.3411Z"
      fill="#207EED"
    />
    <Path
      d="M21.8355 14.8707V15.7149C21.8389 15.7333 21.8378 15.7522 21.8323 15.7701C21.8268 15.788 21.817 15.8042 21.8037 15.8174C21.7905 15.8307 21.7743 15.8405 21.7564 15.846C21.7385 15.8515 21.7196 15.8526 21.7012 15.8492H20.6076V19.3411C20.6076 19.3411 20.6076 19.4754 20.4924 19.4754H19.4564C19.4208 19.4754 19.3866 19.4613 19.3614 19.4361C19.3362 19.4109 19.3221 19.3768 19.3221 19.3411V15.8492H18.2476C18.212 15.8492 18.1778 15.835 18.1527 15.8099C18.1275 15.7847 18.1133 15.7505 18.1133 15.7149V14.8707C18.1133 14.8351 18.1275 14.8009 18.1527 14.7757C18.1778 14.7505 18.212 14.7364 18.2476 14.7364H21.7971C21.8174 14.7515 21.8321 14.7728 21.839 14.797C21.8459 14.8213 21.8447 14.8472 21.8355 14.8707Z"
      fill="#207EED"
    />
  </Icon>
)

export default VentilationCalculatorIcon
