import React from 'react'
import { Icon, IIconProps, useTheme } from 'native-base'
import { Path } from 'react-native-svg'

const BuildingCodesOutlinedIcon = ({ color, ...props }: IIconProps) => {
  const { colors } = useTheme() as any
  const fillColor = color || colors.text['50']
  return (
    <Icon width="31" height="30" viewBox="0 0 31 30" {...props}>
      <Path
        d="M23.45 17.475L19.3812 21.5437L19.2125 21.8812H19.1375L18.9125 21.5437L17.7125 20.325L15.8562 22.1812L19.1562 25.5L25.3062 19.3312L23.45 17.475Z"
        fill={fillColor}
      />
      <Path
        d="M7.75623 15.6187H13.3812V6.84375H22.0437V15.6187H24.6687V4.21875H10.7562V12.9937H5.13123V25.6875H13.4V23.0625H7.75623V15.6187Z"
        fill={fillColor}
      />
    </Icon>
  )
}

export default BuildingCodesOutlinedIcon
