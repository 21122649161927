import { Icon } from 'native-base'
import { Path } from 'react-native-svg'
import { ICustomIconProps } from '../interfaces'

const SteepSlopeOutlinedIcon = ({
  fillColor = 'currentColor',
  ...props
}: ICustomIconProps) => (
  <Icon {...props} viewBox="0 0 30 30">
    <Path
      fill={fillColor}
      d="M19.5 27.1875H16.875V21.75H14.0625V27.1875H4.5V12.5625L24.375 3.375V27.1875H21.75V7.5L7.125 14.25V24.5625H11.4375V19.125H19.5V27.1875Z" 
    />
  </Icon>
)

export default SteepSlopeOutlinedIcon