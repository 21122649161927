import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

const ArchiveIcon = (props: IIconProps) => (
  <Icon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <Path
      d="M2.66667 24C1.93333 24 1.30533 23.7391 0.782667 23.2173C0.260889 22.6947 0 22.0667 0 21.3333V4.66667C0 4.33333 0.0555556 4.03333 0.166667 3.76667C0.277778 3.5 0.422222 3.24444 0.6 3L2.46667 0.733333C2.64444 0.488889 2.86667 0.305333 3.13333 0.182667C3.4 0.060889 3.68889 0 4 0H20C20.3111 0 20.6 0.060889 20.8667 0.182667C21.1333 0.305333 21.3556 0.488889 21.5333 0.733333L23.4 3C23.5778 3.24444 23.7222 3.5 23.8333 3.76667C23.9444 4.03333 24 4.33333 24 4.66667V21.3333C24 22.0667 23.7391 22.6947 23.2173 23.2173C22.6947 23.7391 22.0667 24 21.3333 24H2.66667ZM3.2 4H20.8L19.6667 2.66667H4.33333L3.2 4ZM2.66667 6.66667V21.3333H21.3333V6.66667H2.66667ZM12 20L17.3333 14.6667L15.4667 12.8L13.3333 14.9333V9.33333H10.6667V14.9333L8.53333 12.8L6.66667 14.6667L12 20Z"
      fill="currentColor"
    />
  </Icon>
)

export default ArchiveIcon
