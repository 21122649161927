import React from 'react'
import { Icon, IIconProps } from 'native-base'
import { Defs, G, LinearGradient, Path, Rect, Stop } from 'react-native-svg'

const EnterprisePlanIcon = (props: IIconProps) => (
  <Icon {...props} width="164.856" height="67.505" viewBox="0 0 164.856 67.505">
    <Defs>
      <LinearGradient
        id="linear-gradient-enterprise-1"
        x1="0.5"
        x2="0.5"
        y2="1"
        gradientUnits="objectBoundingBox">
        <Stop offset="0" stopColor="#4c448e" />
        <Stop offset="1" stopColor="#262247" />
      </LinearGradient>
      <LinearGradient
        id="linear-gradient-enterprise-2"
        x1="0.5"
        x2="0.5"
        y2="1"
        gradientUnits="objectBoundingBox">
        <Stop offset="0" stopColor="#3c6799" />
        <Stop offset="1" stopColor="#0a223c" />
      </LinearGradient>
    </Defs>
    <G
      id="Illustr_Houses"
      data-name="Illustr/Houses"
      transform="translate(0 0.5)">
      <Rect
        id="Front_Right_House"
        data-name="Front_Right House"
        width="44.414"
        height="47.642"
        transform="translate(114.133 17.929)"
        stroke="rgba(0,0,0,0)"
        strokeMiterlimit="10"
        strokeWidth="1"
        fill="url(#linear-gradient-enterprise-1)"
      />
      <Path
        id="Roof_Right_House"
        data-name="Roof_Right House"
        d="M72.3,0,85.46,17.929h44.414L116.716,0Z"
        transform="translate(28.674)"
        fill="#8681b3"
        stroke="rgba(0,0,0,0)"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
      <Path
        id="Front_Center_House"
        data-name="Front_Center House"
        d="M68.46,14.654,63.938,8.418,48.824,29V62.233h29.9L78.866,29Z"
        transform="translate(19.362 3.339)"
        fill="#fcb627"
        stroke="rgba(0,0,0,0)"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
      <Path
        id="Roof_Center_House"
        data-name="Roof_Center House"
        d="M57.638,8.418,41.854,29h9.735L66.7,8.418Z"
        transform="translate(16.598 3.338)"
        fill="#fa9000"
      />
      <Path
        id="Front_Left_House"
        data-name="Front_Left House"
        d="M53.363,20.241H20.708v37.3h29.9l-.367-33.23Z"
        transform="translate(8.212 8.027)"
        stroke="rgba(0,0,0,0)"
        strokeMiterlimit="10"
        strokeWidth="1"
        fill="url(#linear-gradient-enterprise-2)"
      />
      <Path
        id="Roof_Left_House"
        data-name="Roof_Left House"
        d="M56.608,24.624l1.776-2.317L48.715,9.146H12.556L23.94,24.641Z"
        transform="translate(4.979 3.627)"
        fill="#469bff"
        stroke="rgba(0,0,0,0)"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
      <Path
        id="Sides_Shadow_"
        data-name="Sides (Shadow)"
        d="M100.978,0,87.822,17.929,98.228,32.341l-.141,33.23h-29.9V32.341H58.451l.367,33.23h-29.9v-37.3L17.537,12.774,6.31,28.268v37.3H0V67H164.857V65.571H114.133V17.929Z"
        transform="translate(0)"
        fill="#141c4d"
      />
    </G>
  </Icon>
)

export default EnterprisePlanIcon
