import React from 'react'
import { ITextProps, Stack, Text } from 'native-base'

import { IFontSize } from 'native-base/lib/typescript/theme/base/typography'
import { IStackProps } from 'native-base/lib/typescript/components/primitives'


export interface IPillProps extends IStackProps {
  text?: string | React.ReactNode
  size?: IFontSize
  textProps?: ITextProps
}

const Pill = (props: IPillProps) => {
  const { size = 'xs', text = 'pill', textProps = {}, ...stackProps } = props

  return (
    <Stack
      borderRadius={16}
      borderColor="text.50"
      borderWidth={1}
      alignSelf="flex-start"
      {...stackProps}
    >
      <Text
        px={2}
        py={1}
        numberOfLines={1}
        textAlign="center"
        lineHeight="1.2em"
        fontWeight="700"
        textTransform="uppercase"
        fontSize={size}
        {...textProps}
      >
        {text}
      </Text>
    </Stack >
  )
}

export default Pill
