import React from 'react'
import { Text, Pressable } from 'native-base'

import { ITextProps } from 'native-base/lib/typescript/components/primitives/Text'

export interface IClaimBadgeProps extends ITextProps {
  value: string
  onPress: () => void
  disabled?: boolean
}

const ClaimBadge = ({
  value,
  onPress,
  disabled,
  letterSpacing = 0.1,
  color: colorProp,
  fontSize = '2xs',
  fontWeight = 600,
  lineHeight = 'xs',
  ...props
}: IClaimBadgeProps) => {
  const label = value || 'ADD CLAIM #'
  const primaryColor = 'primary.500'
  const color = colorProp || 'text.50'

  return (
    <Pressable
      hitSlop={{ top: 15, bottom: 15, right: 15, left: 15 }}
      backgroundColor={value ? 'background.800' : primaryColor}
      borderColor={primaryColor}
      borderRadius={4}
      width={85}
      height={15}
      alignItems="center"
      justifyContent="center"
      borderWidth={1}
      _pressed={{ opacity: 0.5 }}
      onPress={onPress}
      disabled={disabled}>
      <Text
        {...props}
        fontSize="2xs"
        _web={{ fontSize: 'xs' }}
        fontWeight={fontWeight}
        color={color}
        lineHeight={lineHeight}
        letterSpacing={letterSpacing}
        numberOfLines={1}>
        {label}
      </Text>
    </Pressable>
  )
}

export default ClaimBadge
