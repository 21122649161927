import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

const RatingStarIcon = (props: IIconProps) => (
  <Icon width="20" height="19" viewBox="0 0 20 19" {...props}>
    <Path d="M18.8416 6.45481L13.1742 5.63115L10.6408 0.494986C10.5716 0.354361 10.4577 0.240522 10.3171 0.171325C9.96441 -0.00278192 9.53584 0.142307 9.3595 0.494986L6.82602 5.63115L1.15861 6.45481C1.00236 6.47713 0.859504 6.55079 0.750129 6.6624C0.617901 6.7983 0.545037 6.98115 0.547548 7.17075C0.550059 7.36035 0.62774 7.5412 0.763522 7.67356L4.86397 11.6713L3.89522 17.3164C3.8725 17.4477 3.88703 17.5828 3.93716 17.7063C3.9873 17.8297 4.07102 17.9367 4.17885 18.015C4.28668 18.0933 4.4143 18.1399 4.54723 18.1494C4.68016 18.1588 4.81308 18.1309 4.93093 18.0686L10.0001 15.4035L15.0693 18.0686C15.2077 18.1423 15.3684 18.1669 15.5225 18.1401C15.9108 18.0731 16.172 17.7048 16.105 17.3164L15.1363 11.6713L19.2367 7.67356C19.3483 7.56418 19.422 7.42133 19.4443 7.26508C19.5046 6.87445 19.2323 6.51284 18.8416 6.45481ZM13.4108 11.1088L14.2166 15.803L10.0001 13.5887L5.78361 15.8053L6.58942 11.1111L3.1787 7.78517L7.89299 7.0999L10.0001 2.82981L12.1073 7.0999L16.8216 7.78517L13.4108 11.1088Z" />
  </Icon>
)

export default RatingStarIcon
