import React from 'react'
import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

const IceAndWaterShieldCalculatorIcon = (props: IIconProps) => (
  <Icon {...props} viewBox="0 0 26 26">
    <Path
      d="M20.5112 5.81466H5.54484V10.9183H20.5112V5.81466Z"
      fill="#207EED"
    />
    <Path
      d="M20.6046 0.5H5.39537C4.09684 0.510092 2.85457 1.04557 1.93809 1.99026C1.02162 2.93494 0.504882 4.21261 0.5 5.54605V20.4348C0.504824 21.77 1.02096 23.0497 1.93692 23.9974C2.85287 24.9452 4.09513 25.4849 5.39537 25.5H20.5673C21.8755 25.5 23.1302 24.9663 24.0552 24.0164C24.9803 23.0665 25.5 21.7781 25.5 20.4348V5.54605C25.4951 4.21261 24.9784 2.93494 24.0619 1.99026C23.1454 1.04557 21.9032 0.510092 20.6046 0.5ZM22.9215 20.4348C22.9215 21.0657 22.6774 21.6709 22.2429 22.1171C21.8084 22.5632 21.2191 22.8139 20.6046 22.8139H5.39537C4.78892 22.799 4.21192 22.5423 3.78648 22.0983C3.36103 21.6543 3.12052 21.0577 3.11584 20.4348V5.54605C3.12064 4.92498 3.36177 4.33047 3.7877 3.88951C4.21363 3.44855 4.79061 3.19611 5.39537 3.18611H20.5673C20.8706 3.18358 21.1715 3.24275 21.4525 3.36021C21.7335 3.47768 21.989 3.65111 22.2044 3.8705C22.4198 4.08989 22.5908 4.35091 22.7074 4.63848C22.8241 4.92606 22.8842 5.23451 22.8842 5.54605L22.9215 20.4348Z"
      fill="#207EED"
    />
    <Path
      d="M6.66592 13.7195H5.30194C5.20907 13.7195 5.13378 13.7968 5.13378 13.8922V20.051C5.13378 20.1464 5.20907 20.2237 5.30194 20.2237H6.66592C6.75879 20.2237 6.83408 20.1464 6.83408 20.051V13.8922C6.83408 13.7968 6.75879 13.7195 6.66592 13.7195Z"
      fill="#207EED"
    />
    <Path
      d="M15.4851 13.7195H14.009C14.009 13.7195 13.8595 13.7195 13.8221 13.873L13.1682 16.7893C13.1682 16.7893 13.1682 16.7893 13.0747 16.7893L12.346 13.8538C12.337 13.8114 12.3128 13.774 12.2782 13.7491C12.2436 13.7243 12.2011 13.7137 12.1592 13.7195H11.0942C11.0513 13.7218 11.0103 13.7379 10.9768 13.7654C10.9432 13.793 10.919 13.8306 10.9073 13.873L10.216 16.7893L9.39387 13.8538C9.38806 13.8256 9.37612 13.799 9.35893 13.7762C9.34175 13.7533 9.31977 13.7348 9.29462 13.7219C9.26948 13.709 9.24183 13.702 9.21372 13.7016C9.18561 13.7012 9.15778 13.7073 9.13229 13.7195H7.73094C7.61883 13.7195 7.56278 13.7195 7.60015 13.9114L9.3565 20.0702C9.37451 20.1106 9.40349 20.1447 9.43995 20.1685C9.47642 20.1924 9.51881 20.2049 9.56203 20.2045H10.8513C10.8909 20.2045 10.9289 20.1884 10.957 20.1596C10.985 20.1308 11.0007 20.0917 11.0007 20.051L11.5613 16.9812L12.1966 20.051C12.1966 20.1662 12.1966 20.2045 12.3834 20.2045H13.7661C13.8073 20.2065 13.848 20.1943 13.8818 20.17C13.9156 20.1457 13.9406 20.1106 13.9529 20.0702L15.6345 13.9114C15.6403 13.8883 15.6408 13.8641 15.636 13.8408C15.6311 13.8175 15.621 13.7956 15.6065 13.777C15.592 13.7584 15.5735 13.7435 15.5524 13.7335C15.5313 13.7235 15.5083 13.7187 15.4851 13.7195Z"
      fill="#207EED"
    />
    <Path
      d="M18.9417 16.3672C18.0075 16.0602 17.6712 15.9259 17.6712 15.5806C17.6712 15.2352 17.8954 15.1393 18.2877 15.1393C18.5032 15.1121 18.7203 15.1735 18.8918 15.3101C19.0634 15.4467 19.1753 15.6474 19.2033 15.8684C19.2033 15.8684 19.2033 15.8684 19.3715 15.8684H20.6981C20.7209 15.8715 20.7441 15.8693 20.7659 15.8617C20.7877 15.8541 20.8075 15.8415 20.8238 15.8248C20.8401 15.8081 20.8524 15.7877 20.8597 15.7654C20.8671 15.743 20.8693 15.7191 20.8662 15.6957C20.848 15.3825 20.7676 15.0763 20.63 14.7962C20.4924 14.516 20.3004 14.2677 20.066 14.0664C19.8315 13.8652 19.5594 13.7153 19.2665 13.6259C18.9736 13.5366 18.6659 13.5096 18.3625 13.5468C17.0172 13.5468 15.9895 14.3143 15.9895 15.4655C15.9895 16.6167 17.2601 17.3841 18.1009 17.5568C18.9417 17.7295 19.2033 17.8446 19.2033 18.1899C19.2033 18.5353 19.0912 18.6504 18.5867 18.6504C18.0822 18.6504 17.6525 18.3051 17.6525 17.9981C17.6525 17.9981 17.6525 17.9021 17.4843 17.9021H16.1577C16.1349 17.899 16.1117 17.9013 16.0899 17.9088C16.0681 17.9164 16.0482 17.929 16.032 17.9457C16.0157 17.9624 16.0034 17.9828 15.996 18.0052C15.9887 18.0276 15.9865 18.0514 15.9895 18.0748V18.1708C15.9895 19.4371 16.9798 20.2429 18.4559 20.2429C19.932 20.2429 20.9036 19.4563 20.9036 18.3242C20.9036 17.1922 19.5583 16.5783 18.9417 16.3672Z"
      fill="#207EED"
    />
  </Icon>
)

export default IceAndWaterShieldCalculatorIcon
