import React from 'react'
import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

const ChevronRightIcon = (props: IIconProps) => (
  <Icon {...props} viewBox="0 0 9 14">
    <Path
      d="M8.53036 6.55042L0.480359 0.262918C0.459324 0.246358 0.434044 0.236066 0.407424 0.233226C0.380803 0.230385 0.353922 0.23511 0.329866 0.246858C0.30581 0.258606 0.285555 0.276901 0.271428 0.299642C0.257301 0.322383 0.249875 0.348647 0.250002 0.375418V1.75578C0.250002 1.84328 0.291073 1.9272 0.35893 1.98078L6.7875 7.00042L0.35893 12.0201C0.289287 12.0736 0.250002 12.1576 0.250002 12.2451V13.6254C0.250002 13.7451 0.387502 13.8111 0.480359 13.7379L8.53036 7.45042C8.59878 7.39705 8.65413 7.32878 8.6922 7.2508C8.73027 7.17282 8.75005 7.08719 8.75005 7.00042C8.75005 6.91364 8.73027 6.82801 8.6922 6.75004C8.65413 6.67206 8.59878 6.60379 8.53036 6.55042Z"
      fill="#1AA7FF"
    />
  </Icon>
)

export default ChevronRightIcon
