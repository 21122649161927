import React from 'react'
import { Icon, IIconProps, useTheme } from 'native-base'
import { Path } from 'react-native-svg'


const CreativeWindIcon = ({ color, ...props }: IIconProps) => {
  const { colors } = useTheme() as any
  const fillColor = color || colors.secondary['500']

  return (
    <Icon {...props} width="36" height="36" viewBox="0 0 36 36" >
      <Path d="M16.875 20.925H4.5V24.075H16.875C17.3345 24.075 17.7752 24.2575 18.1001 24.5824C18.425 24.9073 18.6075 25.348 18.6075 25.8075C18.6075 26.267 18.425 26.7077 18.1001 27.0326C17.7752 27.3575 17.3345 27.54 16.875 27.54C16.4173 27.5342 15.98 27.3498 15.6564 27.0261C15.3327 26.7025 15.1483 26.2652 15.1425 25.8075H11.9925C11.9925 26.7732 12.2789 27.7171 12.8153 28.5201C13.3518 29.323 14.1144 29.9488 15.0065 30.3183C15.8987 30.6879 16.8804 30.7846 17.8275 30.5962C18.7746 30.4078 19.6446 29.9428 20.3274 29.2599C21.0103 28.5771 21.4753 27.7071 21.6637 26.76C21.8521 25.8129 21.7554 24.8312 21.3858 23.939C21.0163 23.0469 20.3905 22.2843 19.5876 21.7478C18.7846 21.2114 17.8407 20.925 16.875 20.925Z" fill={fillColor} />
      <Path d="M17.19 11.5875H4.5V14.7375H17.19V11.5875Z" fill={fillColor} />
      <Path d="M24.3 5.04C22.5204 5.04427 20.8066 5.71369 19.4953 6.9168C18.1839 8.11992 17.3697 9.76981 17.2125 11.5425H20.3625C20.5442 10.5595 21.0849 9.67929 21.8796 9.07287C22.6742 8.46646 23.666 8.17722 24.662 8.26139C25.6581 8.34556 26.5873 8.79713 27.2689 9.52831C27.9505 10.2595 28.3358 11.218 28.35 12.2175C28.3441 13.2898 27.9155 14.3165 27.1573 15.0748C26.399 15.833 25.3723 16.2616 24.3 16.2675H4.5V19.4175H24.3C25.2779 19.4722 26.2566 19.3269 27.1764 18.9903C28.0962 18.6538 28.9376 18.1331 29.6492 17.4602C30.3608 16.7873 30.9277 15.9763 31.3151 15.0768C31.7025 14.1773 31.9023 13.2081 31.9023 12.2287C31.9023 11.2493 31.7025 10.2802 31.3151 9.38072C30.9277 8.4812 30.3608 7.67018 29.6492 6.99727C28.9376 6.32435 28.0962 5.8037 27.1764 5.46716C26.2566 5.13062 25.2779 4.98527 24.3 5.04Z" fill={fillColor} />
    </Icon>
  )
}

export default CreativeWindIcon
