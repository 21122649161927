import React from 'react'
import _ from 'lodash'
import { Icon, IIconProps, useTheme } from 'native-base'
import { G, Rect, Line, Polyline, Circle } from 'react-native-svg'

const PropertyDetails = (props: IIconProps) => {
  return (
    <Icon width="100px" height="100px" viewBox="0 0 100 100" {...props}>
      <G
        id="Artboard"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fill-rule="evenodd">
        <Polyline
          id="Path"
          stroke="#469BFF"
          strokeWidth="6"
          stroke-linecap="round"
          stroke-linejoin="round"
          points="3.0228526 58.7415416 49.930737 21.3939701 96.6815191 58.5916477"></Polyline>
        <Polyline
          id="Path-2"
          stroke="#469BFF"
          strokeWidth="6"
          stroke-linecap="round"
          stroke-linejoin="round"
          points="40.3441555 92.7193959 16.4213003 92.7193959 16.3029329 22.990109 29.0086349 22.986495 29.1270024 37.8019807"></Polyline>
        <Circle
          id="Oval"
          stroke="#469BFF"
          strokeWidth="6"
          cx="62.338118"
          cy="69.4374004"
          r="17.3809239"></Circle>
        <Line
          x1="89.2328222"
          y1="96.5320087"
          x2="75.0885684"
          y2="82.3877549"
          id="Path-3"
          stroke="#469BFF"
          strokeWidth="6"
          stroke-linecap="round"></Line>
      </G>
    </Icon>
  )
}

export default PropertyDetails
