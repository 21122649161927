import React from 'react'
import { Icon, IIconProps, useTheme } from 'native-base'
import { Path } from 'react-native-svg'

const PermitFeeIcon = ({ color, ...props }: IIconProps) => {
  const { colors } = useTheme() as any
  const fillColor = color || colors.secondary['500']
  return (
    <Icon width="30" height="30" viewBox="0 0 30 30" {...props} >
      <Path
        d="M22.7915 8.16925H7.99414V13.1539H22.7915V8.16925Z"
        fill={fillColor} />
      <Path
        d="M22.7915 3H7.80916C5.21961 3 3 5.21538 3 7.8V22.2C3 24.7846 5.21961 27 7.80916 27H22.7915C25.566 27 27.6007 24.7846 27.6007 22.2V7.8C27.7857 5.21538 25.566 3 22.7915 3ZM25.1961 22.2C25.1961 23.4923 24.0863 24.4154 22.9765 24.4154H7.80916C6.51438 24.4154 5.58955 23.3077 5.58955 22.2V7.8C5.58955 6.50769 6.51438 5.58462 7.80916 5.58462H22.7915C24.0863 5.58462 25.0111 6.50769 25.0111 7.8L25.1961 22.2Z"
        fill={fillColor} />
      <Path
        d="M9.68513 17.0957C9.8477 17.1822 9.97234 17.303 10.0627 17.4616C10.1512 17.6185 10.1945 17.8006 10.1945 18.0043C10.1945 18.2062 10.1475 18.3829 10.0554 18.5362C9.9615 18.6894 9.83144 18.8084 9.66165 18.8913C9.49366 18.9761 9.29858 19.0175 9.08001 19.0175H8.60134C8.58508 19.0175 8.57785 19.0265 8.57785 19.041V20.0542C8.57785 20.0758 8.57244 20.0921 8.55979 20.1047C8.54895 20.1173 8.53089 20.1227 8.51102 20.1227H7.79752C7.77585 20.1227 7.75959 20.1173 7.74695 20.1047C7.73611 20.0921 7.72888 20.0758 7.72888 20.0542V17.0344C7.72888 17.0145 7.73611 16.9983 7.74695 16.9857C7.75959 16.9731 7.77585 16.9677 7.79752 16.9677H9.11975C9.3347 16.9677 9.52256 17.0109 9.68513 17.0957ZM9.24981 18.2549C9.31664 18.1954 9.35096 18.1179 9.35096 18.0223C9.35096 17.9232 9.31664 17.8439 9.24981 17.7826C9.18117 17.7231 9.09085 17.6924 8.98066 17.6924H8.60134C8.58508 17.6924 8.57785 17.7014 8.57785 17.7158V18.3198C8.57785 18.3342 8.58508 18.3415 8.60134 18.3415H8.98066C9.09085 18.3415 9.18117 18.3126 9.24981 18.2549Z"
        fill={fillColor} />
      <Path
        d="M12.839 17.6744C12.8282 17.687 12.8101 17.6924 12.7903 17.6924H11.4391C11.4247 17.6924 11.4175 17.7014 11.4175 17.7158V18.1395C11.4175 18.154 11.4247 18.1612 11.4391 18.1612H12.2755C12.2953 18.1612 12.3134 18.1684 12.3242 18.1792C12.3369 18.1918 12.3423 18.208 12.3423 18.2297V18.8156C12.3423 18.8372 12.3369 18.8535 12.3242 18.8643C12.3134 18.8769 12.2953 18.8823 12.2755 18.8823H11.4391C11.4247 18.8823 11.4175 18.8913 11.4175 18.9058V19.3745C11.4175 19.3889 11.4247 19.3961 11.4391 19.3961H12.7903C12.8101 19.3961 12.8282 19.4033 12.839 19.4142C12.8517 19.4268 12.8571 19.443 12.8571 19.4646V20.0542C12.8571 20.0758 12.8517 20.0921 12.839 20.1047C12.8282 20.1173 12.8101 20.1227 12.7903 20.1227H10.6353C10.6154 20.1227 10.5974 20.1173 10.5865 20.1047C10.5739 20.0921 10.5685 20.0758 10.5685 20.0542V17.0344C10.5685 17.0145 10.5739 16.9983 10.5865 16.9857C10.5974 16.9731 10.6154 16.9677 10.6353 16.9677H12.7903C12.8101 16.9677 12.8282 16.9731 12.839 16.9857C12.8517 16.9983 12.8571 17.0145 12.8571 17.0344V17.6257C12.8571 17.6473 12.8517 17.6636 12.839 17.6744Z"
        fill={fillColor} />
      <Path
        d="M14.8422 20.0722L14.3455 18.9274C14.34 18.9166 14.331 18.9094 14.3184 18.9094H14.1287C14.1143 18.9094 14.107 18.9184 14.107 18.9328V20.0542C14.107 20.0758 14.0998 20.0921 14.089 20.1047C14.0763 20.1173 14.0601 20.1227 14.0384 20.1227H13.3249C13.305 20.1227 13.287 20.1173 13.2761 20.1047C13.2635 20.0921 13.2581 20.0758 13.2581 20.0542V17.0344C13.2581 17.0145 13.2635 16.9983 13.2761 16.9857C13.287 16.9731 13.305 16.9677 13.3249 16.9677H14.6941C14.8982 16.9677 15.0788 17.0091 15.2342 17.0939C15.3877 17.1786 15.5087 17.2958 15.5954 17.4472C15.6803 17.5987 15.7237 17.7735 15.7237 17.9719C15.7237 18.1702 15.6749 18.3451 15.5774 18.4929C15.478 18.6425 15.3444 18.7507 15.1728 18.8192C15.1565 18.8264 15.1529 18.8372 15.1583 18.8517L15.7327 20.0416C15.7381 20.0596 15.7418 20.0722 15.7418 20.0722C15.7418 20.0884 15.7345 20.0993 15.7237 20.1083C15.711 20.1173 15.6966 20.1227 15.6785 20.1227H14.9199C14.8801 20.1227 14.8548 20.1065 14.8422 20.0722ZM14.107 17.7158V18.2423C14.107 18.2585 14.1143 18.2657 14.1287 18.2657H14.5532C14.6507 18.2657 14.7284 18.2387 14.788 18.1864C14.8494 18.1341 14.8783 18.0656 14.8783 17.9809C14.8783 17.8943 14.8494 17.824 14.788 17.7717C14.7284 17.7195 14.6507 17.6924 14.5532 17.6924H14.1287C14.1143 17.6924 14.107 17.7014 14.107 17.7158Z"
        fill={fillColor} />
      <Path
        d="M18.2363 16.9677H18.9407C18.9624 16.9677 18.9787 16.9731 18.9913 16.9857C19.0022 16.9983 19.0094 17.0145 19.0094 17.0344V20.0542C19.0094 20.0758 19.0022 20.0921 18.9913 20.1047C18.9787 20.1173 18.9624 20.1227 18.9407 20.1227H18.2272C18.2056 20.1227 18.1893 20.1173 18.1785 20.1047C18.1658 20.0921 18.1604 20.0758 18.1604 20.0542V18.419C18.1604 18.4064 18.1568 18.4009 18.1514 18.3991C18.1442 18.3973 18.1387 18.4009 18.1333 18.41L17.8262 18.9094C17.8082 18.94 17.7793 18.9544 17.7432 18.9544H17.3999C17.3638 18.9544 17.3385 18.94 17.3205 18.9094L17.0134 18.41C17.0062 18.4009 17.0008 18.3973 16.9953 18.3991C16.9881 18.4009 16.9863 18.4064 16.9863 18.419V20.0542C16.9863 20.0758 16.9791 20.0921 16.9682 20.1047C16.9556 20.1173 16.9393 20.1227 16.9177 20.1227H16.2042C16.1843 20.1227 16.1662 20.1173 16.1554 20.1047C16.1427 20.0921 16.1373 20.0758 16.1373 20.0542V17.0344C16.1373 17.0145 16.1427 16.9983 16.1554 16.9857C16.1662 16.9731 16.1843 16.9677 16.2042 16.9677H16.9086C16.9448 16.9677 16.9719 16.9821 16.9899 17.0127L17.5589 17.9268C17.5679 17.9448 17.577 17.9448 17.586 17.9268L18.155 17.0127C18.1731 16.9821 18.2002 16.9677 18.2363 16.9677Z"
        fill={fillColor} />
      <Path
        d="M19.5116 20.1047C19.4989 20.0921 19.4935 20.0758 19.4935 20.0542V17.0344C19.4935 17.0145 19.4989 16.9983 19.5116 16.9857C19.5224 16.9731 19.5387 16.9677 19.5604 16.9677H20.2739C20.2955 16.9677 20.3118 16.9731 20.3244 16.9857C20.3353 16.9983 20.3425 17.0145 20.3425 17.0344V20.0542C20.3425 20.0758 20.3353 20.0921 20.3244 20.1047C20.3118 20.1173 20.2955 20.1227 20.2739 20.1227H19.5604C19.5387 20.1227 19.5224 20.1173 19.5116 20.1047Z"
        fill={fillColor} />
      <Path
        d="M23.2235 16.9857C23.2343 16.9983 23.2415 17.0145 23.2415 17.0344V17.6293C23.2415 17.6509 23.2343 17.6672 23.2235 17.6798C23.2108 17.6924 23.1946 17.6978 23.1729 17.6978H22.4143C22.3998 17.6978 22.3926 17.705 22.3926 17.7195V20.0542C22.3926 20.0758 22.3854 20.0921 22.3745 20.1047C22.3619 20.1173 22.3456 20.1227 22.3239 20.1227H21.6104C21.5888 20.1227 21.5725 20.1173 21.5617 20.1047C21.549 20.0921 21.5436 20.0758 21.5436 20.0542V17.7195C21.5436 17.705 21.5346 17.6978 21.5201 17.6978H20.7849C20.7633 17.6978 20.747 17.6924 20.7344 17.6798C20.7217 17.6672 20.7163 17.6509 20.7163 17.6293V17.0344C20.7163 17.0145 20.7217 16.9983 20.7344 16.9857C20.747 16.9731 20.7633 16.9677 20.7849 16.9677H23.1729C23.1946 16.9677 23.2108 16.9731 23.2235 16.9857Z"
        fill={fillColor} />
    </Icon>
  )
}

export default PermitFeeIcon
