import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

const ArrowDownIcon = (props: IIconProps) => (
  <Icon width="36" height="36" viewBox="0 0 36 36" {...props}>
    <Path d="M32.946 7.71436H29.9326C29.7277 7.71436 29.5348 7.8148 29.4143 7.97953L17.9996 23.7135L6.58483 7.97953C6.4643 7.8148 6.27144 7.71436 6.06653 7.71436H3.05314C2.79198 7.71436 2.6393 8.01168 2.79198 8.22462L16.9589 27.7554C17.4732 28.4626 18.5259 28.4626 19.0362 27.7554L33.2031 8.22462C33.3598 8.01168 33.2072 7.71436 32.946 7.71436Z" fill="currentColor" />
  </Icon>
)

export default ArrowDownIcon
