import React from 'react'
import { Icon, IIconProps, useTheme } from 'native-base'
import { Path } from 'react-native-svg'

const HomeIcon = ({ color, ...props }: IIconProps) => {
  const { colors } = useTheme() as any
  const fillColor = color || colors.text['50']
  return (
    <Icon width="20" height="20" viewBox="0 0 20 20" {...props}>
      <Path
        d="M10 0.9375L0.3125 8.75L0.466309 8.95447C0.893496 9.52416 1.70921 9.62241 2.25952 9.17053L10 2.8125L17.7405 9.17053C18.2908 9.62241 19.1065 9.52416 19.5337 8.95447L19.6875 8.75L16.875 6.48193V2.5H14.0625V4.21387L10 0.9375ZM10 4.0625L2.5 10V17.5H17.5V10.9375L10 4.0625ZM8.125 10.625H11.875V16.25H8.125V10.625Z"
        fill={fillColor}
      />
    </Icon>
  )
}

export default HomeIcon
