import React from 'react'
import { Icon, IIconProps, useTheme } from 'native-base'
import { Path } from 'react-native-svg'

const EmailIcon = ({ color, ...props }: IIconProps) => {
  const { colors } = useTheme() as any
  const fillColor = color || colors.text['50']
  return (
    <Icon width="19" height="16" viewBox="0 0 19 16" {...props} >
      <Path
        d="M9.5166 8.97803C9.85693 8.97803 10.1724 8.82031 10.5376 8.45508L17.7593 1.30811C17.4521 1.00928 16.8794 0.868164 16.0576 0.868164H2.69336C1.99609 0.868164 1.50635 1.00098 1.23242 1.2749L8.49561 8.45508C8.85254 8.81201 9.16797 8.97803 9.5166 8.97803ZM0.543457 13.834L6.41211 8.03174L0.526855 2.22949C0.402344 2.46191 0.335938 2.86035 0.335938 3.43311V12.5972C0.335938 13.1865 0.402344 13.6016 0.543457 13.834ZM18.4648 13.8257C18.5977 13.585 18.6641 13.1782 18.6641 12.5972V3.43311C18.6641 2.87695 18.5977 2.47852 18.4814 2.25439L12.6128 8.03174L18.4648 13.8257ZM2.94238 15.1704H16.3066C17.0039 15.1704 17.4854 15.0376 17.7593 14.772L11.7744 8.84521L11.2266 9.40137C10.6538 9.94922 10.1143 10.1982 9.5166 10.1982C8.91064 10.1982 8.37109 9.94922 7.80664 9.40137L7.25049 8.84521L1.28223 14.7637C1.59766 15.0376 2.14551 15.1704 2.94238 15.1704Z"
        fill={fillColor}
      />
    </Icon>
  )
}

export default EmailIcon
