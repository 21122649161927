import React from 'react'
import { Icon } from 'native-base'
import { Path } from 'react-native-svg'
import { ICustomIconProps } from '../interfaces'

const CommercialProperty = ({
  fillColor = "#FFF",
  ...props }: ICustomIconProps) => (
  <Icon {...props} viewBox="0 0 30 30">
    <Path
      d="M9.84377 15.225H8.47502C8.38183 15.225 8.30627 15.3005 8.30627 15.3937V21.4312C8.30627 21.5244 8.38183 21.6 8.47502 21.6H9.84377C9.93697 21.6 10.0125 21.5244 10.0125 21.4312V15.3937C10.0125 15.3005 9.93697 15.225 9.84377 15.225Z"
      fill={fillColor}
    />
    <Path
      d="M15.9 17.025C15.9 15.675 15.0375 15.15 13.5562 15.15H11.25C11.1375 15.15 11.0625 15.15 11.0625 15.3188V21.3375C11.0625 21.45 11.0625 21.5063 11.25 21.5063H13.6312C14.925 21.5063 15.9937 21.0188 15.9937 19.6313C16.0113 19.3504 15.9505 19.0702 15.818 18.8219C15.6856 18.5736 15.4868 18.3669 15.2438 18.225C15.4488 18.1001 15.6173 17.9235 15.7325 17.7129C15.8477 17.5022 15.9055 17.265 15.9 17.025ZM12.7687 16.7625H13.4812C13.9125 16.7625 14.1375 16.9313 14.1375 17.2688C14.1375 17.6063 13.9125 17.7563 13.4812 17.7563H12.8437L12.7687 16.7625ZM13.6312 20.1188H12.8437V19.2188H13.65C14.0625 19.2188 14.2875 19.4063 14.2875 19.7438C14.2875 20.0813 14.0625 20.1188 13.6312 20.1188Z"
      fill={fillColor}
    />
    <Path
      d="M20.3812 17.5313H21.75C21.8625 17.5313 21.9187 17.5313 21.9187 17.4375C21.916 17.1224 21.8465 16.8115 21.7148 16.5252C21.5832 16.2389 21.3924 15.9837 21.1551 15.7765C20.9177 15.5692 20.6392 15.4146 20.3377 15.3228C20.0363 15.231 19.7188 15.204 19.4062 15.2438C19.0907 15.2099 18.7716 15.242 18.4691 15.3382C18.1667 15.4343 17.8875 15.5923 17.6495 15.8022C17.4114 16.0121 17.2196 16.2691 17.0863 16.5571C16.9529 16.8451 16.881 17.1577 16.875 17.475V19.35C16.8684 19.6742 16.9303 19.9961 17.0565 20.2947C17.1827 20.5934 17.3705 20.862 17.6076 21.0832C17.8447 21.3044 18.1258 21.4731 18.4324 21.5783C18.7391 21.6835 19.0646 21.7228 19.3875 21.6938C19.7016 21.7337 20.0207 21.7063 20.3234 21.6133C20.6262 21.5204 20.9057 21.3641 21.1433 21.1548C21.381 20.9456 21.5714 20.6881 21.7018 20.3995C21.8323 20.111 21.8999 19.7979 21.9 19.4813C21.9 19.4813 21.9 19.4813 21.7312 19.4813H20.3625C20.3625 19.4813 20.1937 19.4813 20.1937 19.575C20.1937 19.7888 20.1088 19.9939 19.9576 20.1451C19.8064 20.2963 19.6013 20.3813 19.3875 20.3813C19.1737 20.3813 18.9686 20.2963 18.8174 20.1451C18.6662 19.9939 18.5812 19.7888 18.5812 19.575V17.7C18.5812 17.4862 18.6662 17.2811 18.8174 17.1299C18.9686 16.9787 19.1737 16.8938 19.3875 16.8938C19.6013 16.8938 19.8064 16.9787 19.9576 17.1299C20.1088 17.2811 20.1937 17.4862 20.1937 17.7C20.1937 17.7 20.2875 17.5313 20.3812 17.5313Z"
      fill={fillColor}
    />
    <Path
      d="M14.4938 3.33752V10.4625H3.46875V26.475H21.3V23.85H6.09375V13.0875H17.1188V5.96252H23.9062V26.4563H26.5312V3.33752H14.4938Z"
      fill={fillColor}
    />
  </Icon>
)

export default CommercialProperty
