import React from 'react'
import { Icon, IIconProps, useTheme } from 'native-base'
import { Path } from 'react-native-svg'

const WasteIcon = ({ color, ...props }: IIconProps) => {
  const { colors } = useTheme() as any
  const fillColor = color || colors.text['500']
  return (
    <Icon width="31" height="30" viewBox="0 0 31 30" {...props} >
      <Path
        d="M22.6625 8.02502H7.6438V13.0125H22.6625V8.02502Z"
        fill={fillColor}
      />
      <Path
        d="M22.7188 2.83124H7.5313C6.22172 2.83123 4.9655 3.35017 4.03773 4.27442C3.10997 5.19868 2.58626 6.45292 2.5813 7.76249V22.3125C2.58624 23.6238 3.10934 24.88 4.03658 25.8072C4.96381 26.7344 6.22 27.2575 7.5313 27.2625H22.7188C24.0316 27.2625 25.2907 26.741 26.219 25.8127C27.1473 24.8844 27.6688 23.6253 27.6688 22.3125V7.76249C27.6638 6.45292 27.1401 5.19868 26.2124 4.27442C25.2846 3.35017 24.0284 2.83123 22.7188 2.83124ZM25.0813 22.3125C25.0813 22.621 25.02 22.9264 24.9008 23.211C24.7816 23.4955 24.607 23.7535 24.3871 23.9699C24.1672 24.1863 23.9065 24.3568 23.6201 24.4714C23.3336 24.586 23.0273 24.6425 22.7188 24.6375H7.5313C6.91619 24.6326 6.32766 24.3861 5.89269 23.9511C5.45773 23.5161 5.2112 22.9276 5.2063 22.3125V7.76249C5.21125 7.14911 5.45838 6.56255 5.89387 6.13057C6.32936 5.6986 6.91791 5.45622 7.5313 5.45624H22.7188C23.0232 5.45376 23.3251 5.51159 23.6071 5.62638C23.8891 5.74117 24.1456 5.91066 24.3617 6.12506C24.5778 6.33946 24.7494 6.59454 24.8665 6.87557C24.9835 7.15661 25.0438 7.45804 25.0438 7.76249L25.0813 22.3125Z"
        fill={fillColor}
      />
      <Path
        d="M8.56252 21.4875L7.02502 16.35C7.02502 16.2375 7.02502 16.2 7.15627 16.2H8.41252C8.44963 16.1942 8.48755 16.2026 8.51879 16.2234C8.55004 16.2442 8.57233 16.276 8.58127 16.3125L9.21877 18.75L9.80002 16.3125C9.80234 16.2927 9.80856 16.2736 9.81833 16.2563C9.82809 16.2389 9.84121 16.2237 9.85692 16.2115C9.87262 16.1993 9.8906 16.1903 9.9098 16.1851C9.92901 16.1799 9.94905 16.1786 9.96877 16.1812H10.8688C10.9059 16.1755 10.9438 16.1838 10.975 16.2046C11.0063 16.2255 11.0286 16.2573 11.0375 16.2937L11.7313 18.75L12.3125 16.3125C12.3171 16.276 12.3349 16.2425 12.3626 16.2183C12.3902 16.1941 12.4258 16.1809 12.4625 16.1812H13.7188C13.8313 16.1812 13.8688 16.1812 13.85 16.3312L12.5 21.4687C12.4914 21.5067 12.4698 21.5406 12.4391 21.5645C12.4083 21.5884 12.3702 21.6009 12.3313 21.6H11.225C11.2053 21.6026 11.1853 21.6013 11.1661 21.5961C11.1468 21.5909 11.1289 21.5819 11.1132 21.5697C11.0975 21.5575 11.0843 21.5423 11.0746 21.5249C11.0648 21.5076 11.0586 21.4885 11.0563 21.4687L10.5125 18.9L10.0438 21.4687C10.0415 21.4885 10.0352 21.5076 10.0255 21.5249C10.0157 21.5423 10.0026 21.5575 9.98688 21.5697C9.97118 21.5819 9.9532 21.5909 9.934 21.5961C9.91479 21.6013 9.89475 21.6026 9.87502 21.6H8.73127C8.69475 21.602 8.65855 21.5922 8.62811 21.5719C8.59768 21.5516 8.57466 21.522 8.56252 21.4875Z"
        fill={fillColor}
      />
      <Path
        d="M14.225 19.9312C14.225 19.8375 14.225 19.8 14.375 19.8H15.5187C15.6125 19.8 15.65 19.8 15.65 19.8C15.65 20.0625 15.9687 20.3625 16.4562 20.3625C16.9437 20.3625 16.9812 20.1187 16.9812 19.9687C16.9812 19.8187 16.6062 19.6125 16.0437 19.4437C15.4812 19.275 14.1687 18.8062 14.1687 17.7C14.1687 16.5937 15.05 16.05 16.1937 16.05C16.4624 16.0092 16.7368 16.0273 16.9978 16.1031C17.2589 16.1789 17.5003 16.3105 17.7054 16.4889C17.9105 16.6672 18.0744 16.888 18.1857 17.136C18.297 17.384 18.353 17.6532 18.35 17.925C18.3532 17.9454 18.3515 17.9663 18.3451 17.986C18.3387 18.0056 18.3277 18.0235 18.3131 18.0381C18.2985 18.0527 18.2806 18.0637 18.2609 18.0701C18.2413 18.0765 18.2204 18.0782 18.2 18.075H17.0937H16.9437C16.9437 17.7937 16.6625 17.475 16.1562 17.475C15.65 17.475 15.6312 17.625 15.6312 17.85C15.6312 18.075 15.9125 18.2437 16.7187 18.5062C17.525 18.7687 18.4062 18.9187 18.4062 20.1C18.4062 21.2812 17.5437 21.7687 16.3062 21.7687C15.0687 21.7687 14.225 21 14.225 19.9312Z"
        fill={fillColor}
      />
      <Path
        d="M23.2625 16.3313V17.3063C23.2625 17.3063 23.2625 17.4375 23.1312 17.4375H21.875V21.3938C21.8782 21.4142 21.8765 21.4351 21.8701 21.4547C21.8637 21.4744 21.8527 21.4923 21.8381 21.5069C21.8235 21.5215 21.8056 21.5325 21.7859 21.5389C21.7663 21.5453 21.7454 21.547 21.725 21.5438H20.5625C20.542 21.547 20.5211 21.5453 20.5015 21.5389C20.4818 21.5325 20.4639 21.5215 20.4493 21.5069C20.4347 21.4923 20.4237 21.4744 20.4173 21.4547C20.4109 21.4351 20.4092 21.4142 20.4125 21.3938V17.5125H19.1937C19.1 17.5125 19.0437 17.5125 19.0437 17.3813V16.3313C19.0437 16.2375 19.0437 16.2 19.1937 16.2H23.2437C23.2437 16.2 23.2625 16.2375 23.2625 16.3313Z"
        fill={fillColor}
      />
    </Icon>
  )
}

export default WasteIcon
